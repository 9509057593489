import { COMMON } from 'constants/images';
import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import CfImage from 'components/common/CfImage';
import { useRouter } from 'next/router';
import ScreenBox from 'components/common/ScreenBox';
import Image from 'next/image';
import nav1 from 'assets/image/nav1.png';
import nav2 from 'assets/image/nav2.jpg';
import nav3 from 'assets/image/nav3.png';
import nav4 from 'assets/image/nav4.png';
import chat from 'assets/image/chat.svg';
import question from 'assets/image/question.svg';
import clsx from 'clsx';
import usePlatform from 'hooks/usePlatform';
import Smartbanner from './SmartBanner';
import Mobile from './Mobile';
import styles from './style.module.scss';

const changeNumber = 40;

interface Props {
  simple?: boolean;
  showLoginModal?: boolean;
}

const NavContent = ({ children }) => {
  const router = useRouter();
  const [rest, setRest] = useState(false);

  const handleChange = useCallback(() => {
    setRest(true);
    setTimeout(() => {
      setRest(false);
    }, 200);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleChange);

    return () => {
      router.events.off('routeChangeComplete', handleChange);
    };
  }, [handleChange, router.events]);

  return (
    <div
      style={{
        borderBottom: '1px solid #fff3',
      }}
      className={clsx(
        'absolute left-0 top-0 hidden w-full justify-start overflow-hidden bg-black pt-[72px] text-white sm:pt-[52px]',
        {
          'group-hover:flex': !rest,
        }
      )}
    >
      <div className="pl-10">{children}</div>
    </div>
  );
};

// const LinkStyle = ({ href, target = '_self', children }) => {
//   return (
//     <Link
//       href={href}
//       target={target}
//       className="mb-[10px] text-base font-normal text-[#b2b2b2] hover:text-ab-orange md:mb-5 [&:last-child]:md:mb-0"
//     >
//       {children}
//     </Link>
//   );
// };

// const DownloadIco = () => {
//   return (
//     <>
//       <Link href="/tools/app">
//         <div className="group/item mb-5 h-14 w-auto cursor-pointer sm:mb-11 md:mr-[60px]">
//           <div className="flex items-center font-SemiBold text-xl group-hover/item:text-ab-orange">
//             <CfImage
//               src="https://gcs-airbrush.mr.pixocial.com/5264847e19fb775861ab76c0033b50d8.png"
//               width={20}
//               className=" mr-1.5"
//             />
//             {$t({ defaultMessage: 'AirBrush Mobile', id: 'gYhCHv' })}
//           </div>
//           <p className="text-base font-normal text-[#b2b2b2] group-hover/item:text-ab-orange">
//             {$t({ defaultMessage: 'Photo Editor For Mobile', id: '8l00K/' })}
//           </p>
//         </div>
//       </Link>
//       <Link href="/tools/desktop">
//         <div className="group/item mb-5 h-14 w-auto cursor-pointer md:mb-0 md:mr-[60px]">
//           <div className="flex items-center font-SemiBold text-xl group-hover/item:text-ab-orange">
//             <CfImage
//               src="https://gcs-airbrush.mr.pixocial.com/59514caa113035ec566bd1d20688219b.png"
//               width={20}
//               className=" mr-1.5"
//             />
//             {$t({ defaultMessage: 'AirBrush Mac', id: 'HnLhZc' })}
//           </div>
//           <p className="text-base font-normal text-[#b2b2b2] group-hover/item:text-ab-orange">
//             {$t({ defaultMessage: 'Photo Editor For Mac', id: 'T/Nvwp' })}
//           </p>
//         </div>
//       </Link>
//     </>
//   );
// };

// const EditorNav = () => {
//   return (
//     <div className="flex flex-col pt-0 text-zinc-50 md:flex-row md:py-10">
//       <div className="mt-5 md:mt-0">
//         <DownloadIco />
//       </div>
//       {/* <div className={`${styles.line} hidden md:block`}></div>
//       <div className="flex flex-col pl-0 sm:pt-7 md:pl-[60px] md:pt-0">
//         <h2 className="mb-2 font-SemiBold text-xl md:mb-5">
//           {$t({ defaultMessage: 'Online AI Editor', id: 'eN5Kp7' })}
//         </h2>
//         <LinkStyle href="/aigc/portrait">
//           {$t({ defaultMessage: 'AI Photo Editor', id: '4QNDMB' })}
//         </LinkStyle>
//         <LinkStyle href="/aigc/headshots/home">
//           {$t({ defaultMessage: 'AI Headshot Editor', id: 'mi5q70' })}
//         </LinkStyle>
//         <LinkStyle href="https://vmake.ai">
//           {$t({ defaultMessage: 'AI E-commerce Editor', id: '/dv5Yw' })}
//         </LinkStyle>
//       </div> */}
//     </div>
//   );
// };

const FeaturesNav = () => {
  return (
    <div className="flex h-auto flex-col pt-0 text-zinc-50 md:flex-row md:py-10">
      <div className="flex w-auto flex-col pt-7 md:w-52 md:pt-0">
        <>
          <h2 className="mb-2 hidden font-SemiBold text-xl md:mb-5 md:block">
            {$t({ defaultMessage: 'Features', id: 'ZXp0z1' })}
          </h2>
          <p className="text-sm leading-5 text-[rgba(255,255,255,0.80)]">
            {$t({
              defaultMessage:
                'Easily enhance, edit, and retouch photos with Airbrush App. Download now for free to experience flawless results.',
              id: 'RdbTGm',
            })}
          </p>
        </>
      </div>
      <div className="flex flex-col flex-wrap pl-0 pt-7 md:flex-row md:pl-20 md:pt-0">
        <Link className="mb-5 mr-5 flex h-[102px]" href="/retouch" title="Photo Retouching">
          <Image
            src={nav2}
            alt="Photo Retouching"
            width={128}
            height={102}
            className="rounded-[1.25rem]"
          />
          <div className="ml-4 w-auto md:w-48">
            <h3 className="mb-2 font-PoppinsBold text-sm hover:text-[#ff824e]">
              {$t({ defaultMessage: 'Photo Retouch', id: 'oih2xB' })}
            </h3>
            <p className="text-xs text-[gba(255,255,255,0.80)]">
              {$t({
                defaultMessage: 'Revamp portraits with AI-powered tool for flawless results. ',
                id: '452i7C',
              })}
            </p>
          </div>
        </Link>

        <Link href="/eraser" className="mb-5 mr-5 flex h-[102px]" title="Remove Unwanted Object">
          <Image src={nav4} alt="Remove Unwanted Object" width={128} height={102} />
          <div className="ml-4 w-auto pr-2 md:w-48">
            <h3 className="mb-2 font-PoppinsBold text-sm hover:text-[#ff824e]">
              {$t({ defaultMessage: 'Remove Unwanted Object', id: '/1vrQd' })}
            </h3>
            <p className="text-xs text-[gba(255,255,255,0.80)]">
              {$t({
                defaultMessage: 'Effortlessly remove objects or people from your photos!',
                id: 'oVM/wh',
              })}
            </p>
          </div>
        </Link>

        <Link
          href="/removebg"
          className="mb-5 mr-5 flex h-[102px]"
          title={$t({ defaultMessage: 'Image Background Remover', id: '0ZmpQh' })}
        >
          <Image src={nav1} alt="Background Remover" width={128} height={102} />
          <div className="ml-4 w-auto md:w-48">
            <h3 className="mb-2 font-PoppinsBold text-sm hover:text-[#ff824e]">
              {$t({ defaultMessage: 'Image Background Remover', id: '0ZmpQh' })}
            </h3>
            <p className="text-xs text-[gba(255,255,255,0.80)]">
              {$t({
                defaultMessage: 'Easily remove the background from images',
                id: 'MQ6eKj',
              })}
            </p>
          </div>
        </Link>

        <Link href="/batch" className="mb-5 mr-5 flex h-[102px]" title="Batch Photo Editor">
          <Image src={nav3} alt="Batch Photo Editor" width={128} height={102} />
          <div className="ml-4 w-auto md:w-48">
            <h3 className="mb-2 font-PoppinsBold text-sm hover:text-[#ff824e]">
              {$t({ defaultMessage: 'Batch Photo Editor', id: 'r+WQie' })}
            </h3>
            <p className="text-xs text-[gba(255,255,255,0.80)]">
              {$t({
                defaultMessage: 'Edit multiple images in one go with Airbrush batch photo editor.',
                id: 'GIMFOs',
              })}
            </p>
          </div>
        </Link>

        {/* <LinkStyle href="/tools/retouch">
          {$t({ defaultMessage: 'AI Powered Retouch', id: 'vLC7P+' })}
        </LinkStyle>
        <LinkStyle href="/tools/eraser">{$t({ defaultMessage: 'Eraser', id: 'SvdI9p' })}</LinkStyle>

        <LinkStyle href="/tools/batch">
          {$t({ defaultMessage: 'Batch Editing', id: 'ek8EMd' })}
        </LinkStyle> */}
      </div>
    </div>
  );
};

const ToolsNav = () => {
  const tools = [
    {
      label: $t({ defaultMessage: 'Image Tools', id: 'cVPQ35' }),
      items: [
        {
          label: $t({ defaultMessage: 'Image Enhancer', id: 'cMS2CZ' }),
          href: '/image-enhancer',
        },
        {
          label: $t({ defaultMessage: 'AI Anime', id: 'MaHG6a' }),
          href: '/ai-anime-generator',
        },
      ],
    },
    {
      label: $t({ defaultMessage: 'Video Tools', id: 'aGmTHm' }),
      items: [
        {
          label: $t({ defaultMessage: 'AI Video Enhancer', id: 'adMmvP' }),
          href: '/ai-video-enhancer',
        },
        {
          label: $t({ defaultMessage: 'Video Watermark Remover', id: 'DYutM7' }),
          href: '/video-watermark-remover',
        },
      ],
    },
  ];
  return (
    <div className="flex h-auto flex-col pt-0 text-zinc-50 md:flex-row md:py-10">
      <div className="flex w-auto flex-col pt-7 md:w-52 md:pt-0">
        <>
          <h2 className="mb-2 hidden font-SemiBold text-xl md:mb-5 md:block">
            {$t({ defaultMessage: 'Online Tools', id: 'NWcnUD' })}
          </h2>
          <p className="text-sm leading-5 text-[rgba(255,255,255,0.80)]">
            {$t({
              defaultMessage: 'Edit videos and photos with our AI online tools. No signup needed.',
              id: '5R2af0',
            })}
          </p>
        </>
      </div>
      <div className="flex flex-col flex-wrap pl-0 pt-7 md:flex-row md:pt-0">
        {tools.map((item) => {
          return (
            <div key={item.label} className="flex flex-col md:ml-[100px]">
              <h3 className="mb-2 font-Poppins text-sm text-[#A8A8A8]">{item.label}</h3>
              {item.items.map((subItem) => {
                return (
                  <Link key={subItem.label} href={subItem.href} className="inline-block py-[7.5px]">
                    <h4 className="mb-2 font-Poppins text-base font-semibold hover:text-[#ff824e]">
                      {subItem.label}
                    </h4>
                  </Link>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ResourceNav = () => {
  return (
    <div className="md:h-50 mb-5 mt-0 flex h-auto flex-col pt-5 text-zinc-50 md:my-10 md:flex-row md:pt-0">
      {/* <div className="flex w-auto flex-col pt-7 md:mr-20 md:w-52 md:pt-0">
        <h2 className="mb-5 font-SemiBold text-xl">
          {$t({ defaultMessage: 'Explore', id: '7JlauX' })}
        </h2>
        <p className="text-sm leading-5 text-[rgba(255,255,255,0.80)]">
          {$t({
            defaultMessage: 'Unlock boundless creativity with AirBrush Creative Hub. ',
            id: 'O8aw80',
          })}
        </p>
      </div> */}

      <Link
        rel="nofollow"
        href="/partnership"
        className="mb-5 flex h-[102px] w-[197px] flex-col items-center justify-center rounded-xl border border-solid border-[rgba(255,255,255,0.16)] pt-7 hover:bg-[rgba(255,255,255,0.16)] md:mb-0 md:mr-10 md:w-52 md:pt-0"
      >
        <Image src={chat} alt="Affiliate Program" />
        <h2 className="mt-2 font-SemiBold text-xs">
          {$t({ defaultMessage: 'Affiliate Program', id: '8tvGYT' })}
        </h2>
      </Link>

      <Link
        rel="nofollow"
        target="_blank"
        href="https://help.airbrush.com/hc/en-us"
        className="flex h-[102px] w-[197px] flex-col items-center justify-center rounded-xl border border-solid border-[rgba(255,255,255,0.16)] pt-7 hover:bg-[rgba(255,255,255,0.16)] md:mr-20 md:w-52 md:pt-0"
      >
        <Image src={question} alt="Help Center" />
        <h2 className="mt-2 font-SemiBold text-xs">
          {$t({ defaultMessage: 'Help Center', id: 'HtSDyc' })}
        </h2>
      </Link>
    </div>
  );
};

const Nav = ({ simple }) => {
  return (
    <ul className={`flex min-w-[16rem] text-base text-slate-50 ${simple && 'hidden'}`}>
      {/* <li
        className={`${styles.triangleWrap} group mr-10 flex flex-auto cursor-pointer select-none items-center justify-center text-base font-medium`}
      >
        <div className="z-10 flex items-center justify-center">
          {$t({ defaultMessage: 'Editor', id: 'fWKwFM' })}
          <div className={`${styles.icon} ml-2 h-2.5 w-2.5`}></div>
        </div>
        <NavContent>
          <EditorNav />
        </NavContent>
      </li> */}

      <li className="z-10 mr-10 flex flex-auto cursor-pointer select-none items-center justify-center text-base font-medium">
        <Link href="/">
          <div className="z-10 flex items-center justify-center hover:text-[#ff824e]">
            {$t({ defaultMessage: 'Home', id: 'ejEGdx' })}
          </div>
        </Link>
      </li>

      <li
        className={`${styles.triangleWrap} group mr-10 flex flex-auto cursor-pointer select-none items-center justify-center text-base font-medium`}
      >
        <div className="z-10 flex items-center justify-center">
          {$t({ defaultMessage: 'Features', id: 'ZXp0z1' })}
          <div className={`${styles.icon} ml-2 h-2.5 w-2.5`}></div>
        </div>
        <NavContent>
          <FeaturesNav />
        </NavContent>
      </li>

      <li
        className={`${styles.triangleWrap} group mr-10 flex flex-auto cursor-pointer select-none items-center justify-center text-base font-medium`}
      >
        <div className="z-10 flex items-center justify-center">
          {$t({ defaultMessage: 'Online Tools', id: 'NWcnUD' })}
          <div className={`${styles.icon} ml-2 h-2.5 w-2.5`}></div>
        </div>
        <NavContent>
          <ToolsNav />
        </NavContent>
      </li>

      <li className="z-10 mr-10 flex flex-auto cursor-pointer select-none items-center justify-center text-base font-medium">
        <Link href="/blog">
          <div className="z-10 flex items-center justify-center hover:text-[#ff824e]">
            {$t({ defaultMessage: 'Blog', id: 'tv5FG3' })}
          </div>
        </Link>
      </li>

      <li
        className={`${styles.triangleWrap} group mr-10 flex flex-auto cursor-pointer select-none items-center justify-center text-base font-medium`}
      >
        <div className="z-10 flex items-center justify-center">
          {$t({ defaultMessage: 'Explore', id: '7JlauX' })}
          <div className={`${styles.icon} ml-2 h-2.5 w-2.5`}></div>
        </div>
        <NavContent>
          <ResourceNav />
        </NavContent>
      </li>

      <li className="z-10 mr-10 flex flex-auto cursor-pointer select-none items-center justify-center text-base font-medium">
        <Link href="/tools/app">
          <div className="z-10 flex items-center justify-center hover:text-[#ff824e]">
            {$t({ defaultMessage: 'Download', id: '5q3qC0' })}
          </div>
        </Link>
      </li>

      {/* <li
        className={`${styles.triangleWrap} group mr-10 flex flex-auto cursor-pointer select-none text-base font-medium`}
      >
        <div className="z-10 flex items-center justify-center">
          {$t({ defaultMessage: 'Download', id: '5q3qC0' })}
          <div className={`${styles.icon} ml-2 h-2.5 w-2.5`}></div>
        </div>
        <NavContent>
          <DownloadNav />
        </NavContent>
      </li> */}
    </ul>
  );
};

const Header = (props: Props) => {
  const { simple } = props;

  const [isDark, setIsDark] = useState(false);
  const router = useRouter();
  const isHome = router?.asPath === '/' || router?.pathname === '/';
  const { isMobile } = usePlatform();
  const [mobileNavShow, setMobileNavShow] = useState(false);

  const registerDark = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setIsDark(scrollTop >= changeNumber);
  }, []);

  const [logoUrl, setLogoUrl] = useState('/');

  useEffect(() => {
    // 个人职业照需要先跳转到AI set页面，然后在从AI set页面跳转到官网页
    if (router.pathname === '/aigc/headshots/home') {
      setLogoUrl('/aigc');
      // 企业职业照需要先跳转到个人职业照页面，然后在跳AI set页面，最后跳转到官网页
    } else if (router.pathname === '/aigc/team/home') {
      setLogoUrl('/aigc/headshots/home');
    } else {
      setLogoUrl('/');
    }
    setMobileNavShow(false);
  }, [router.pathname]);

  useEffect(() => {
    if (!isHome) {
      setIsDark(true);
      return;
    }
    window.addEventListener('scroll', registerDark, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', registerDark);
    };
  }, [isHome, registerDark]);

  return (
    <>
      <header className={clsx(`fixed top-0 z-30`, { 'bg-black': isDark })} id="header">
        {/* <Smartbanner /> */}
        {isMobile && <Smartbanner />}
        <div
          className={clsx(
            `relative flex w-screen border-b border-solid border-transparent  ${styles.container}`,
            {
              'bg-black': mobileNavShow,
            }
          )}
        >
          <ScreenBox
            pcStyle="z-10"
            pcBox={
              <div className=" h-full flex-none cursor-pointer">
                <Link href={logoUrl} passHref legacyBehavior>
                  <div className="flex h-full items-center pl-[30px]">
                    <CfImage src={COMMON.logo} width={32} height={32} priority />
                    <p className="ml-4 text-2xl font-normal text-white">
                      {$t({ defaultMessage: 'AirBrush', id: '47CPPy' })}
                    </p>
                  </div>
                </Link>
              </div>
            }
            mobileBox={
              <div
                className={`mx-4 flex w-6 items-center justify-center ${simple && 'hidden'}`}
                onClick={() => setMobileNavShow((prev) => !prev)}
              >
                <CfImage
                  src={
                    mobileNavShow
                      ? 'https://gcs-airbrush.mr.pixocial.com/5275fb3dd6b51d1d38847d2cd7e1a815.png'
                      : 'https://gcs.beautyplus.com/e406e69bd2cc76e75c98b5b6fafd7321.png'
                  }
                  alt="menu-open-icon"
                  width={24}
                  height={24}
                />
              </div>
            }
            mobileDisplay="flex"
          />

          <div className="flex flex-auto justify-center md:ml-[60px] md:justify-start">
            <ScreenBox
              pcBox={
                // childrenMenu
                <Nav simple={simple}></Nav>
              }
              pcDisplay="flex"
              mobileBox={
                <Link href="/" className={`flex items-center ${styles.container}`}>
                  <CfImage src={COMMON.logo} width={18} height={16} priority alt="airbrush-logo" />
                  <p className="ml-4 text-2xl font-normal text-white">
                    {$t({ defaultMessage: 'AirBrush', id: '47CPPy' })}
                  </p>
                </Link>
              }
            />
          </div>
          <div
            className={`z-10 flex items-center justify-end sm:min-w-[200px] ${simple && 'hidden'}`}
          >
            <Link
              rel="nofollow"
              target="_blank"
              className="mr-6 w-5"
              href="https://help.airbrush.com/hc/en-us"
            >
              <CfImage
                src="https://gcs.beautyplus.com/411d370a0c1d2acf56b950fbeb06d3e1.png"
                width={20}
                height={20}
                alt="faq"
              />
            </Link>
            {/* <SignUpBtn isModal={showLoginModal} /> */}
          </div>
        </div>
        <Mobile open={mobileNavShow} />
      </header>
      {/* 非首页时撑起文档流 */}
      {!isHome && <div className={isMobile ? styles.baseBgMobile : styles.baseBgDesktop} />}
    </>
  );
};

export default Header;
