import dayjs from 'dayjs';
import { useMemo } from 'react';
import style from './style.module.scss';

const year = dayjs().year();

export default () => {
  // 不需要翻译
  const str = `© ${year} Pixocial Technology (Singapore) Pte. Ltd.`;

  const legalLink = useMemo(() => {
    return [
      {
        href: '/legal/privacy-policy',
        text: $t({ defaultMessage: 'Privacy Policy', id: 'vx0nkZ' }),
      },
      {
        href: '/legal/terms-of-service',
        text: $t({ defaultMessage: 'Terms of Service', id: '32rBNK' }),
      },
      {
        href: '/legal/refund-policy',
        text: $t({ defaultMessage: 'Refund Policy', id: 'T4gmxU' }),
      },
      {
        href: '/legal/cookies-policy',
        text: $t({ defaultMessage: 'Cookie Policy', id: 'cA0kQY' }),
      },
    ];
  }, []);

  return (
    <footer className={style.footer}>
      <div className="flex w-full max-w-[1200px] flex-col items-center justify-center whitespace-nowrap lg:flex-row lg:justify-between">
        {str}
        <div className="hidden md:flex">
          {legalLink.map(({ href, text }, index) => (
            <a
              key={href}
              href={href}
              target="_blank"
              rel="noreferrer nofollow"
              className={`
               border-solid border-[rgba(255,255,255,.6)] px-2 ${
                 index === legalLink.length - 1 ? 'border-r-0' : 'border-r'
               }
              `}
            >
              {text}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};
