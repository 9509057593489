import { createSlice } from '@reduxjs/toolkit';

// 当前语言
export const localeSlice = createSlice({
  name: 'locale',
  initialState: 'en',
  reducers: {
    updateLocale: (state, { payload }) => {
      state = payload;
      return payload;
    },
  },
});

export const { updateLocale } = localeSlice.actions;

export default localeSlice.reducer;
