export const COMMON = {
  logo: 'https://gcs.beautyplus.com/fdd0e4ffa68a36b1a3021e74738352a0.png',
  abMacLogo: 'https://gcs.beautyplus.com/fdd0e4ffa68a36b1a3021e74738352a0.png',
  lang: 'https://duffle-web.storage.pixocial.com/1320e4098f32590e1601951b7eacf107.png',
  langBlack: 'https://gcs.beautyplus.com/58f997ffb471e10c5c0fdda78641ac15.png',

  arrow: 'https://duffle-web.storage.pixocial.com/1d9bcd2928c77865c1f3cf389eb9379e.png',
  arrowBlack: 'https://gcs.beautyplus.com/e364981ea8f96a5c2fe4df486259b182.png',
  goArrow: 'https://gcs.beautyplus.com/1c9d6e8ecf010106808b50f5940101ba.png',

  facebook: 'https://gcs.beautyplus.com/07f4f6c89e3bcde4e8d36d1559e0a461.png',
  iphone: 'https://gcs.beautyplus.com/cc920982aa53d57f1016ba29e2b9d5b4.png',
  google: 'https://gcs.beautyplus.com/a32475ee98ec0f768f9a3e7c4bccd35e.png',

  checked: 'https://duffle-web.storage.pixocial.com/db79b82032b0e27da66241523b37aaa1.png',
  checkedBlod: 'https://gcs-airbrush.mr.pixocial.com/082b99e6d55ed0f722433cfa1cda8b41.png',
  loading: 'https://duffle-web.storage.pixocial.com/ca6ff8ae86850865aff4872c20e00d0d.png',
  close: 'https://duffle-web.storage.pixocial.com/124a6aa0e503f5d47612f91d6e24776c.png',

  level: {
    error: 'https://duffle-web.storage.pixocial.com/ee71f1679b5f78390d99c708f21b9753.png',
    success: 'https://duffle-web.storage.pixocial.com/57dcc02094f42fda7d9556b32fa8b022.png',
    warning: 'https://duffle-web.storage.pixocial.com/cbf91701fa4e4064d603f8e47781a3d6.png',
  },

  logoMap: {
    vmake: 'https://gcs-airbrush.mr.pixocial.com/34992fb2348a0f4a04b125a42b597991.svg',
    abs: 'https://gcs-airbrush.mr.pixocial.com/fe965e2edfc1e42d790f784e11ee4b70.png',
  },
};

export const INDEX = {
  banner: 'https://gcs.beautyplus.com/6036c980d469e6b7be751a02df28971f.jpeg',
  android: 'https://gcs.beautyplus.com/e18fa4b01927d61de4799d870712e4c1.png',
  ios: 'https://gcs.beautyplus.com/5e08a506ee50491bca203d66e90d7e80.png',
  mac: 'https://gcs.beautyplus.com/78ccd196bccd3ac8e118b4eaa0bd022d.png',

  retouch: {
    '1': 'https://gcs.beautyplus.com/da5da26999e66017d09413637b1b3ba1.png',
    '2': 'https://gcs.beautyplus.com/b6c28bef60a06717630baa0efb5d6d3c.png',
    '3': 'https://gcs.beautyplus.com/d4943cb5513f283ed869818aff02555b.png',
    '4': 'https://gcs.beautyplus.com/295dd327049a91a8b2cbc5733308d9ab.png',
    '5': 'https://gcs.beautyplus.com/23320869038114d21441e88a4d2d4b5e.png',
    '6': 'https://gcs.beautyplus.com/67786aed0a43185603dddefc7d71cbdd.png',
  },
  makeup: {
    '1': 'https://gcs.beautyplus.com/8d8d9652f138468f8f7d892221038134.png',
    '1-big': 'https://gcs.beautyplus.com/363196869d47fb6ede1dbaa5da8046c3.png',
    '2': 'https://gcs.beautyplus.com/b261e08e34ff078d658b427041bb546e.png',
    '2-big': 'https://gcs.beautyplus.com/9a32fe96fcd4ed6264ea5788a3ac6769.png',
    '3': 'https://gcs.beautyplus.com/4709153d3789a6a437371493a65e402c.png',
    '3-big': 'https://gcs.beautyplus.com/a7e54426fa340daab75c1f323cf721f8.png',
    '4': 'https://gcs.beautyplus.com/247223ef82fb0a83a5afc460c9fe2057.png',
    '4-big': 'https://gcs.beautyplus.com/ec14a68cdedc5d59b5ddd1cedf6d2743.png',
    '5': 'https://gcs.beautyplus.com/5aa37fcf899416b72e5df911b0a70cdc.png',
    '5-big': 'https://gcs.beautyplus.com/78abced6d0186ab25f1469cc795c9769.png',
    line: 'https://gcs.beautyplus.com/cf730e1f89a22f561653cfe1bcb2990f.png',
  },
  filters: {
    '1': 'https://gcs.beautyplus.com/326b249e1023395e1aaa2634b9969908.png',
    '1-active': 'https://gcs.beautyplus.com/78473f5313f5e54c181be533c39fd581.png',
    '2': 'https://gcs.beautyplus.com/8b73b96d3a8dac0df9d119460b6682f7.png',
    '2-active': 'https://gcs.beautyplus.com/0bd56c8d4bc73c2d8198643d3eb4c96a.png',
    '3': 'https://gcs.beautyplus.com/9d407ed05d763a09cf6dc7b1ba08f027.png',
    '3-active': 'https://gcs.beautyplus.com/867a368246c6b7d57c8b6dabfa676d62.png',
    '4': 'https://gcs.beautyplus.com/ddd10d3fdbb5e7f073762be75d73bc91.png',
    '4-active': 'https://gcs.beautyplus.com/6d65fa77af753d51477e3b7cdca43d3b.png',
    '5': 'https://gcs.beautyplus.com/7a6ce59bd2d6587f5fadd73378e8420e.png',
    '5-active': 'https://gcs.beautyplus.com/e698dbdac5a67215f39cf8ac357a29bf.png',
    '6': 'https://gcs.beautyplus.com/9143e44079641457d7ba5e65a78c9410.png',
    '6-active': 'https://gcs.beautyplus.com/7f209908ceced72864f6139b89d9b8bc.png',
  },
  eraser: {
    before: 'https://gcs.beautyplus.com/7de6de8e9267187aa5313c2a92a7a9cf.png',
    after: 'https://gcs.beautyplus.com/7ae02b0097b4734dd135c70a78ff765e.png',
    line: 'https://gcs.beautyplus.com/a4465fe2b6a8ad6ac06b6d339762878f.png',
    icon: 'https://gcs.beautyplus.com/8f66557a431f1cf502abdbea45443874.png',
  },
  effective: {
    absLogo: 'https://gcs.beautyplus.com/0abc946e8d0cd98d94f48e61bbcb0071.png',
    abLogo: 'https://gcs.beautyplus.com/aee8258cfc36efc3b8aaeb431ee78dd1.png',
    download1: 'https://gcs.beautyplus.com/d767fd141174f47dc55e90bdc5f133a3.png',
    download2: 'https://gcs.beautyplus.com/245fba2341a121171b9853437e28df2a.png',

    appBtnActive: 'https://gcs.beautyplus.com/24d690068a66f7fc29235c18832797cf.png',
    appBtn: 'https://gcs.beautyplus.com/27f28d582f8ca08363912b53614856e1.png',
    googleBtnActive: 'https://gcs.beautyplus.com/c514ffc3536af700cc19ad71a315f5aa.png',
    googleBtn: 'https://gcs.beautyplus.com/f5ee57ccc5601586acd5894f326814e6.png',
  },
  batchEditing: {
    card1: 'https://gcs.beautyplus.com/c4d564c493d5f5303a2a9e5c9aebfe3b.png',
    card2: 'https://gcs.beautyplus.com/a4634fc40e0046fb11ad7a119756740a.png',
  },
};

export const LOGIN = {
  'signup-bg': 'https://duffle-web.storage.pixocial.com/5df9fd22bad46154ea08110d766ed2a6.jpeg',
  checkbox: 'https://duffle-web.storage.pixocial.com/7648ede0b1cafaa4b5434ff479820ee1.png',
  eye: 'https://duffle-web.storage.pixocial.com/94ba4a6adc7b85147aa99dcf10d75fab.png',
  'eye-off': 'https://duffle-web.storage.pixocial.com/b2e080611c67a98b0dd167f13aefacf4.png',
  'eye-hover': 'https://duffle-web.storage.pixocial.com/0a57fec2d58d8336129ae26322f66c6d.png',
  'eye-off-hover': 'https://duffle-web.storage.pixocial.com/0e26291a009d10488e2ad51a59346c37.png',

  google: 'https://gcs.beautyplus.com/bce0e9e98b51f069e7e40adbdacc1846.png',
  facebook: 'https://gcs.beautyplus.com/abdcec4c4ab1cde8c0e5fcafe7357cba.png',
  apple: 'https://gcs.beautyplus.com/87ba72d43c95f80faf293c29b71d73c8.png',
  arrow: 'https://gcs.beautyplus.com/0be49a0bd1f4ce9a8f87f82253af2364.png',

  arrowTiny: 'https://gcs.beautyplus.com/128671d9322d6d46845e50409398ce20.png',

  bg: 'https://gcs.beautyplus.com/9759e0ac6dda963d2b25aeea9da4937a.png',
  bgNew: 'https://gcs-airbrush.mr.pixocial.com/4963b14513269f5cba07f061190fa9c7.png',
};

export const FEATURES = {
  acne_after: 'https://duffle-web.storage.pixocial.com/b31e467e9e21c7caba858649bca974bb.jpeg',
  acne_before: 'https://duffle-web.storage.pixocial.com/c771ec7cb701a107e6d1b8eb81ea970f.jpeg',

  align_after: 'https://duffle-web.storage.pixocial.com/09d374d184e2b4f0d6e782f5971fffb7.jpeg',
  align_before: 'https://duffle-web.storage.pixocial.com/6a21e56061426f1f2a38bb2005e77d39.jpeg',

  dark_circle_after:
    'https://duffle-web.storage.pixocial.com/bd7c028a09007a5f5471271ce4a7423f.jpeg',
  dark_circle_before:
    'https://duffle-web.storage.pixocial.com/87a1cc68d6896a38c8a6ae03305a9b48.jpeg',

  eye_brighten_after:
    'https://duffle-web.storage.pixocial.com/fa97d992cc3ca32e19658ba30ba01c10.jpeg',
  eye_brighten_before:
    'https://duffle-web.storage.pixocial.com/10b25aac017380f748f677e9c5fe7df2.jpeg',

  firm_after: 'https://duffle-web.storage.pixocial.com/007ae48af1a5ba5ea74984d16a1641f0.jpeg',
  firm_before: 'https://duffle-web.storage.pixocial.com/a1e415e643dfb4f8434116f38ca275de.jpeg',

  lip_after: 'https://duffle-web.storage.pixocial.com/9c827c0e62dca67d316751af1a9b52f9.jpeg',
  lip_before: 'https://duffle-web.storage.pixocial.com/c6cdd6be7eb10523a8271d4aec95c3b3.jpeg',

  makeup_after: 'https://duffle-web.storage.pixocial.com/f8046234076c90d7c10d5c610901015d.jpeg',
  makeup_before: 'https://duffle-web.storage.pixocial.com/7b3ec145d13a286279447f8c8cd3785c.jpeg',

  resize_after: 'https://duffle-web.storage.pixocial.com/34185121153c1ab00138a29c3a78cc60.jpeg',
  resize_before: 'https://duffle-web.storage.pixocial.com/a9ef73e4cb1d6892f05544afbef92f62.jpeg',

  skintone_after: 'https://duffle-web.storage.pixocial.com/2cdd213a2b9da9ed361116c5cd77e838.jpeg',
  skintone_before: 'https://duffle-web.storage.pixocial.com/789f4e90244608f4dbd4148e5b663071.jpeg',

  slim_after: 'https://duffle-web.storage.pixocial.com/d5a73f07dc587524e444dfb86a83049c.jpeg',
  slim_before: 'https://duffle-web.storage.pixocial.com/4c02d4c5be0d639eb14cec19e10bcd56.jpeg',

  smooth_after: 'https://duffle-web.storage.pixocial.com/5583bf7db1f8fa73940164a0e930cd18.jpeg',
  smooth_before: 'https://duffle-web.storage.pixocial.com/7d45f97dbd410ca9965d1ca89d14727b.jpeg',

  teeth_after: 'https://duffle-web.storage.pixocial.com/daa6d7e299eb9b1f1fc7681952d31865.jpeg',
  teeth_before: 'https://duffle-web.storage.pixocial.com/c4c6b467663f160878519c9eeff79e8e.jpeg',

  line: 'https://duffle-web.storage.pixocial.com/45d9ca52aceca23ad73eeb9319585b2d.png',
};

export const PRICING = {
  'form-bg': 'https://duffle-web.storage.pixocial.com/c26bbb77bad5edfc530873c9353a1499.png',
  logo: 'https://duffle-web.storage.pixocial.com/c7ad0eb10b1e03894ebc0e24d6a2a085.png',
  checkCircleFilled: 'https://duffle-web.storage.pixocial.com/03714cd227e727de52385d724de4f3f4.png',
  selected: 'https://duffle-web.storage.pixocial.com/03714cd227e727de52385d724de4f3f4.png',
  'select-default': 'https://duffle-web.storage.pixocial.com/50c1be890f54056c9e5269ea81061302.png',
  'down-bg': 'https://duffle-web.storage.pixocial.com/80114c58fdfb94502345ddc51d44e743.png',
  pricingBg: 'https://gcs-airbrush.mr.pixocial.com/f4077ddf6b9c40cbe6bc12a3551716da.png',
  tick: 'https://gcs-airbrush.mr.pixocial.com/57ae4e341ff60bc9b8fdaf8245e80bf3.png',

  '0': 'https://gcs.beautyplus.com/fa7469ccfb820ee4d8bb6ffb2eae3beb.jpeg',
  '1': 'https://duffle-web.storage.pixocial.com/04f3f1714a30c15ab2ac0d27f36c0deb.jpeg',
  '2': 'https://duffle-web.storage.pixocial.com/899c5b09ecc0bedc6c4ed28629295190.jpeg',
  '3': 'https://duffle-web.storage.pixocial.com/38b2d010b29dece3d86e3e9f29999517.jpeg',

  aigc: [
    'https://gcs.beautyplus.com/7566b8d88d67240e3d8381f90c058235.png',
    'https://gcs.beautyplus.com/f920f654353f2c32df9d7a4a92607a13.png',
    'https://gcs.beautyplus.com/b458af6ff201c1c5171da7510ff64ac4.png',
    'https://gcs.beautyplus.com/f42830d4c1269eb76d74ef6a933c4dd3.png',
  ],

  'btn-bg': 'https://duffle-web.storage.pixocial.com/0a44b6d1a06d4d3a4dcd26351e3af644.png',
  'pricing-result': 'https://duffle-web.storage.pixocial.com/3f939939539c09fa2b88fa58fb6250b4.png',
  'success-big': 'https://duffle-web.storage.pixocial.com/7d51e8a39613aa2cba3ae1938a2da1ed.png',

  m: {
    0: 'https://gcs.beautyplus.com/0989eba9fc441309dcaaf599d5915085.png',
    1: 'https://gcs.beautyplus.com/37af82f2b207ed7d526946e5103d9ed3.png',
    2: 'https://gcs.beautyplus.com/3415c03c588fd54d104826202ad61a06.png',
    3: 'https://gcs.beautyplus.com/f1fd51bfad2ec48f145c1c78616f033a.png',
  },
  star: 'https://gcs-airbrush.mr.pixocial.com/933a1d0c77fffa21bafffa5f4f9e8cef.png',
  star2: 'https://gcs-airbrush.mr.pixocial.com/9c4c45c16882d385aeb7a5f9fedd89cf.png',
};

export const DOWNLOAD = {
  bg: 'https://duffle-web.storage.pixocial.com/56e3cd9336ea78eb1841c45c74c33fd6.png',
  radio: 'https://duffle-web.storage.pixocial.com/98a24f8075128f92236eacc0c2853f3d.png',
  app: 'https://gcs.beautyplus.com/568290d1759656e14e6db4dd283a9b83.png',

  downloading_chrome:
    'https://duffle-web.storage.pixocial.com/ce970dd2e14d29ce4cb5a64e6243aa2a.png',
  downloading_firefox:
    'https://duffle-web.storage.pixocial.com/3194b332a5941d94366300684e79e60c.png',
  downloading_safari:
    'https://duffle-web.storage.pixocial.com/df79e67be367404053bf764d40c1b9c9.png',
  icon_youtube: 'https://duffle-web.storage.pixocial.com/4e5be6f1cd49755cd62a5ef44cd109da.png',
  m: {
    banner: 'https://gcs.beautyplus.com/ade1f1a9cf1320c50af96bd8d9c39757.png',
  },
};

// 支付
export const PAYMENT = {
  bg: 'https://gcs.beautyplus.com/5bbede0399e47120948b752b99f2d41e.png',
  result: {
    bg: 'https://gcs.beautyplus.com/c03ce5626c9dcaeb56a897f793d73543.png',
    close: 'https://gcs.beautyplus.com/a15c8f44b42374211282acea81964441.png',
  },
};

// 个人中心
export const PERSONAL_ACCOUNT = {
  bg: 'https://gcs.beautyplus.com/3b8127d896c64f20e1da27303abf1149.jpeg',
  edit: 'https://gcs.beautyplus.com/250f9c59041038bf711feb11fefd0d3c.png',
  selectIcon: 'https://gcs.beautyplus.com/0f02ba3301a5df5350b2fcbe2d6d898c.png',
};

// Lora
export const Lora = {
  coverIndex: 'https://gcs.beautyplus.com/ed66baadfadcd656652377de6e26d885.jpeg',

  mark: 'https://gcs.beautyplus.com/24d84489845f4370e907bde88ad9bd74.png',
  upload: 'https://gcs.beautyplus.com/528e82a9df333280a51150d61c93a1e9.png',
  remove: 'https://gcs.beautyplus.com/af11ac97310a97e994b59c9ad93d58f9.png',

  introduceBg: 'https://gcs.beautyplus.com/56b769350207ec7eed12d3120711b5f9.png',

  tools: {
    portrait: 'https://gcs.beautyplus.com/401d2d06960b8af62df0f1f469b1def0.png',
    headshots: 'https://gcs.beautyplus.com/669d943baade34f96fa7c603dca29076.png',
    avatar: 'https://gcs.beautyplus.com/587a31babaa539846cf13d2a934ccd67.png',
  },
  loading: 'https://gcs.beautyplus.com/54675c76a231e65883c24ea6ee0b782a.png',
  operate: 'https://gcs.beautyplus.com/7d4c9df3d2e65fa465d520d4615e39b5.png',
  arrow: 'https://gcs.beautyplus.com/0c73749951d6e30e4f29bb0d929a48c4.png',

  introduce: {
    aiPortrait: 'https://gcs.beautyplus.com/93637e59f343c1425c65a153bba5583e.png',
    aiHead: 'https://gcs.beautyplus.com/1f0be33f860b24f5c3451aa2b1686ced.png',
    aiAvatar: 'https://gcs.beautyplus.com/b2df4f76c2ed290524b30affd5dfab50.png',
    aiLora: 'https://gcs-airbrush.mr.pixocial.com/1713ad41d01cf1c2191e45747075ed3c.png',
  },
  close: 'https://gcs.beautyplus.com/7216ff3f9b7957562dc198b729f6bab5.png',

  tipsGood: [
    'https://gcs.beautyplus.com/9929a8f180c661e1ab747af57e49a01e.png',
    'https://gcs.beautyplus.com/46547f20e29a8d2287245bcef0193d94.png',
    'https://gcs.beautyplus.com/4dd5cb4795e7ea5342ca17b71b509066.png',
    'https://gcs.beautyplus.com/2d6200200287cd75ae7e6d83fa6f2599.png',
  ],
  tipsBad: [
    'https://gcs.beautyplus.com/3cd5528b28ccaceb09fced00252f12c5.png',
    'https://gcs.beautyplus.com/35cb5761a37934b2a9333aad19af9b6c.png',
    'https://gcs.beautyplus.com/ec240827c6a4f4cfbecdd4fccf36515c.png',
    'https://gcs.beautyplus.com/a3f7cf766044c2a77ae8b4bb040f5e69.png',
  ],

  rightIcon: 'https://gcs.beautyplus.com/c619be89ee64272c030439cab855ed7e.png',
  errorIcon: 'https://gcs.beautyplus.com/af0a87bad6e015132e8ec03f5f395a97.png',
};

export const PRICING_TIMES = {
  cover: 'https://gcs-airbrush.mr.pixocial.com/34e6af51eb069b927fa82c6537b5fe6e.png',
};
// 客户端的限时促销
export const NEW_USER_SALE = {
  bg: 'https://gcs.beautyplus.com/86fdacc2b42264138319e769c21a5112.png',
  selected: 'https://gcs-airbrush.mr.pixocial.com/d71db50a42a203b5f9079c068a55ce12.png',
  planBg: 'https://gcs-airbrush.mr.pixocial.com/2c82a9e1b0810160973dfcc46d07226d.png',
  planBorder: 'https://gcs-airbrush.mr.pixocial.com/c6d44f537058eb8f725732739761ae4f.png',
};
