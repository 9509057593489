import { TYPE, TRACE_ID } from 'constants/sentry';
import axios from 'axios';
import { getToken } from 'utils/login';
import type { AxiosRequestConfig } from 'axios';
import { fatalReport, warningReport, getTraceId } from 'utils/sentry';

// axios配置
type CustomAxiosOptionsType = Omit<AxiosRequestConfig, 'method' | 'url'>;

// axios请求参数.
export type Params = any;

// axios请求参数.
export type TypeResponse<Data = any> = {
  data: Data;
  code: number;
  message: string;
};

const getDefaultHeaders = () => {
  const token = getToken();

  const defaultHeaders: any = {
    'x-athena-tenant-id': process.env.tenantID,
    'X-Athena-App-Id': process.env.appID,
    [TRACE_ID]: getTraceId(),
  };
  if (token) {
    defaultHeaders.Authorization = `Bearer ${token}`;
  }
  return defaultHeaders;
};

// 接口异常上报sentry
const reportHttpError = (error) => {
  if ([404, 401].includes(error?.response?.data?.code)) {
    return;
  }

  try {
    const { url, params, data } = error.config;

    // 4xx改为waring级别
    const reportFn = [400].includes(error.response?.status) ? warningReport : fatalReport;

    reportFn({
      type: TYPE.ERROR_API,
      message: {
        url: url,
        status: error.response?.status,
        data: error.response.data,
        params: params || data,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

axios.interceptors.response.use(
  (response): TypeResponse => {
    return response?.data;
  },
  (error) => {
    const resError = error;
    if (!resError?.response) {
      resError.response = {};
    }
    if (['ERR_NETWORK', 'ECONNABORTED'].includes(resError.code)) {
      // 网络错误
      resError.response.data = {
        code: 404,
      };
    }
    if (!resError?.response?.data) {
      resError.response.data = {};
    }
    if (
      resError?.response?.status === 401 ||
      [11308, 11309, 30002, 30004].includes(resError.response.data.code)
    ) {
      // 未登录，错误码参考：https://docs.pixocial.io/athena/docs/tutorial-basics/error/
      resError.response.data.code = 401;
    }

    reportHttpError(resError);

    return resError?.response?.data;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const get = async <Data = any,>(
  url: string,
  params: Params = {},
  options: CustomAxiosOptionsType = {},
  headers = {}
): Promise<TypeResponse<Data>> => {
  return axios({
    method: 'get',
    url,
    params,
    headers: {
      ...getDefaultHeaders(),
      ...headers,
    },
    ...options,
  }) as unknown as Promise<TypeResponse<Data>>;
};

export const post = async (
  url: string,
  params: Params,
  options: CustomAxiosOptionsType = {},
  headers = {}
) => {
  return axios({
    method: 'post',
    url,
    data: params,
    headers: {
      ...getDefaultHeaders(),
      ...headers,
    },
    ...options,
  }) as unknown as Promise<TypeResponse>;
};

export const patch = async (
  url: string,
  params: Params,
  options: CustomAxiosOptionsType = {},
  headers = {}
) => {
  return axios({
    method: 'patch',
    url,
    data: params,
    headers: {
      ...getDefaultHeaders(),
      ...headers,
    },
    ...options,
  }) as unknown as Promise<TypeResponse>;
};

export const put = async (
  url: string,
  params: Params,
  options: CustomAxiosOptionsType = {},
  headers = {}
) => {
  return axios({
    method: 'put',
    url,
    data: params,
    headers: {
      ...getDefaultHeaders(),
      ...headers,
    },
    ...options,
  }) as unknown as Promise<TypeResponse>;
};
