import { isAPPClient } from 'utils/client-msg';

type TypeSystem = 'mac_desk' | 'mac_web' | 'ios' | 'android' | 'windows_web' | 'null';

let system: TypeSystem;
export function getSystem() {
  if (system) {
    return system;
  }

  if (isAPPClient) {
    system = 'mac_desk';
  } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    system = 'ios';
  } else if (/Android/i.test(navigator.userAgent)) {
    system = 'android';
  } else if (/Mac/i.test(navigator.userAgent)) {
    system = 'mac_web';
  } else if (/Windows/i.test(navigator.userAgent)) {
    system = 'windows_web';
  } else {
    system = 'null';
  }

  return system;
}
