import { post, get, patch } from 'utils/http';
import { getAirbrushApi } from 'utils/domain';

const baseURLAirbrushApi = getAirbrushApi();

// 登录/注册
const baseURL = process.env.loginAPI;

// 刷新token
const baseURLRefresh = process.env.refreshAPI;

const baseURLPricing = process.env.pricingAPI;

// 刷新token
export const apiRefreshToken = (params, headers) =>
  post(
    '/oauth2/refresh',
    params,
    {
      baseURL: baseURLRefresh,
    },
    headers
  );

// 注册
export const apiSignup = (params) =>
  post('/api/v1/account', params, {
    baseURL,
  });

// 是否已注册
export const apiAccountExists = (params) =>
  get('/api/v1/account/exists', params, {
    baseURL,
  });

// 邮箱登录
export const apiLogin = (params) => {
  return post('/account/email-login', params, {
    baseURL: baseURLAirbrushApi,
    withCredentials: true,
  });
};

// 退出登录
export const apiSignOut = (params = {}) =>
  get('/oauth2/end', params, {
    baseURL,
  });

// 发送验证码
export const apiSendVerify = (params) =>
  post('/api/v1/account/send_verification_link', params, {
    baseURL,
  });

// 重置密码
export const apiResetPwd = (params) =>
  patch('/api/v1/account/pwd', params, {
    baseURL,
  });

// 获取chargebee的购买页
export const apiChargebeeBuy = (params) =>
  get('/chargebee/v1/subscription/checkout', params, {
    baseURL,
  });

// 获取个人页
export const apiCustomerSelf = (params = {}) =>
  get('/api/v1/customer/portal', params, {
    baseURL: baseURLPricing,
  });

// 修改chargebee的语言
export const apiCustomerLanguage = (params = {}) =>
  patch('/api/v1/customer/language', params, {
    baseURL: baseURLPricing,
  });

// 第三方登录。参考文档：https://docs.pixocial.io/athena/docs/tutorial-basics/web/#%E8%8E%B7%E5%8F%96%E5%BA%94%E7%94%A8-ui-%E9%85%8D%E7%BD%AE
export const apiAuthLogin = (params) => {
  const { social } = params;
  return get(`/oauth2/redirect/${social}`, params, {
    baseURL,
  });
};
export const apiUserToken = (params) => {
  return post(`/account/oauth2`, params, {
    baseURL: baseURLAirbrushApi,
    withCredentials: true,
  });
};

// 获取用户信息
export const apiUserInfo = () => {
  return get(
    `/account/get-userinfo`,
    {},
    {
      baseURL: baseURLAirbrushApi,
      withCredentials: true,
    }
  );
};

// 重置密码
export const apiSetPwd = (params) =>
  post('/api/v1/account/bind', params, {
    baseURL,
  });
