import { useEffect, useState } from 'react';
import { getPlatformLink } from 'utils/downloadLink';

const usePlatform = () => {
  const [platform, setPlatform] = useState<'iOS' | 'Android' | 'Mac'>();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(/android|iphone|ipad|ipod|mobile/i.test(navigator?.userAgent?.toLowerCase()));
  }, []);

  useEffect(() => {
    const { platform } = getPlatformLink();
    setPlatform(platform);
  }, []);

  return { platform, isMobile };
};

export default usePlatform;
