import { SRC_SET, DPR_LIST, CF_BASIC_URL, QUALITY } from './constant';

import type { TypeImgConfig } from './typing';

// 获取srcSet属性
export const getSrcSet = ({
  src,
  sizes,
  width,
  height,
  quality = QUALITY,
  format,
}: TypeImgConfig) => {
  let srcSetList = [];

  const optionsConfig = [`quality=${quality}`];
  if (format) {
    optionsConfig.push(`format=${format}`);
  }

  if (sizes) {
    // 图片的枚举宽度。
    srcSetList = SRC_SET.map((w) => {
      const options = [`width=${w}`, ...optionsConfig];

      return `${CF_BASIC_URL}/${options.join(',')}/${src} ${w}w`;
    });
  } else if (width || height) {
    // 图片枚举像素。
    srcSetList = DPR_LIST.map((dpr) => {
      const options = [...optionsConfig];
      if (width) {
        options.push(`width=${width * dpr}`);
      }
      if (height) {
        options.push(`height=${height * dpr}`);
      }

      return `${CF_BASIC_URL}/${options.join(',')}/${src} ${dpr}x`;
    });
  }
  return srcSetList.join(', ');
};

// 获取图片链接
export const getSrc = ({
  src,
  quality = QUALITY,
  format = 'auto',
  width,
  height,
}: TypeImgConfig) => {
  const optionsConfig = [`quality=${quality}`];
  if (format) {
    optionsConfig.push(`format=${format}`);
  }
  if (width) {
    optionsConfig.push(`width=${width}`);
  }
  if (height) {
    optionsConfig.push(`height=${height}`);
  }

  return `${CF_BASIC_URL}/${optionsConfig.join(',')}/${src}`;
};

export const getImgConfig = ({
  src,
  sizes,
  quality,
  width,
  height,
  format,
  priority,
}: TypeImgConfig) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const imgProps: any = {};

  // src必须
  if (!src) {
    return {};
  }

  // 优先下载。只支持webp格式，原图可能很大，所以最好不要让原图优先加载
  if (priority) {
    // 立即加载图片
    imgProps.loading = 'eager';
    // 预加载
    // preload(src, 'image');
  }

  if (sizes) {
    // 图片的枚举宽度。
    imgProps.sizes = sizes;
    imgProps.srcSet = getSrcSet({
      src,
      quality,
      format,
      // 获取宽度的srcSet
      sizes,
    });
  } else if (width || height) {
    // 图片枚举dpr。
    if (width) {
      imgProps.width = width;
    }
    if (height) {
      imgProps.height = height;
    }

    imgProps.srcSet = getSrcSet({
      src,
      quality,
      format,
      // 获取dpr的srcSet
      width,
      height,
    });
  } else {
    // 没有图片响应式
    imgProps.src = getSrc({
      src,
      quality,
      format,
    });
  }
  return imgProps;
};
