// 设计稿的图片宽度最小16px，最大1920
export const SRC_SET = [
  16, 32, 48, 64, 96, 128, 256, 374, 640, 750, 828, 1080, 1200, 1920, 2048, 3840,
];

// dpr列表
export const DPR_LIST = [1, 2];

// CF服务地址
export const CF_BASIC_URL = 'https://image-resizing.pixocial.com/cdn-cgi/image';

// webp格式
export const WEBP_FORMAT = 'webp';

// 压缩质量
export const QUALITY = 93;
