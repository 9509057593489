import { customLinkUpdate } from 'constants/blog';
import downloadApple from 'assets/image/download-apple.png';
import downloadGoogle from 'assets/image/download-google.png';
import usePlatform from 'hooks/usePlatform';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    handleLink: (url: string) => void;
  }
}

const Start = ({ index }: { index: number }) => {
  return (
    <Image
      style={{
        height: '12px',
        marginRight: '2px',
        verticalAlign: 'middle',
        width: '12px',
      }}
      width={12}
      height={12}
      key={index + 1}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIuSURBVHgBvVZBaxNBFP7eprhbhNL00iqKSkEpxRbRiwfNrhcv+iMUqSc9iIiotImg4KH1Byj4QzzUIEpPag/iTSN4EsUNIt1u3H1+SWjZmN10Nk36wc7svPfmfftm5r1ZICe06lzQqt3QVcdFTljIDV1kM4KCLiEncpHpK+ccu1J7ADdvdPkiE1zrHOs95ICYGnKvjrH93KWIrbPibayZ+DCPTOLFVHkhug5TFyZG3JujsPRLhjpA0Tkoc/Vf2AFmkRUyomrDQX3zDgyQGpm+HZtA+HcaVjzL0TxFV3n6xnr48enpBd29Y/8NdXtdLnVHKvqaxzfW01CZpuEZOj9A+SHsGvqT7j/y+cT9/gpr5KWwGgTU2Bg+1ILIDewFYlmw5HzwjLlyG8NErHfFC563TiOTcplB3sIwoLokXvik+bp99MXdeMpDch+DRKwVccOHW8OOPBM3eMy1LWMQaBJ5YTkp6kpqrm2FhBUMmKjlO8ueKbHObg65oR+kFJ5K0/QoV9pnYstUliaVTNf2T3LSBPrDVHu+IRka0Qx2g0Z0EsZkkfaxVwmozpiTCY6jN7731KrM5yCT2XQnqLG9KKXNSabHFb7X0u30SKrbVNvqPt5FMp6Q+Kyfy3CDRyKk7LB1Flo/Pookgc8PKu5IpqsYh2VvXXx/uCQrUGdFPN9HBrQ6epiZ3CzmN7eF8Wjx/zlZZO+5lG8Q4QErSg2GaP+roMzL8jIi+4R4v38k9f8AzW25SsL6xaUAAAAASUVORK5CYII="
      alt="start ico"
    />
  );
};

const LinkMap = {
  default: 'https://app.adjust.com/1brnx5z1',
  home: 'https://app.adjust.com/1bwoktc4',
  retouch: 'https://app.adjust.com/1bmf916p',
  eraser: 'https://app.adjust.com/1b1nvib8',
  removebg: 'https://app.adjust.com/1bvfuq5q',
  batch: 'https://app.adjust.com/1bvlbs2f',
  app: 'https://app.adjust.com/1b2ooro2',
  blog: 'https://app.adjust.com/1b8zie1j',
  'image-enhancer': 'https://app.adjust.com/1ac0i5al?campaign=Tools&adgroup=image-enhancer',
  'video-watermark-remover':
    'https://app.adjust.com/1ac0i5al?campaign=Tools&adgroup=video-watermark-remover',
  'video-enhancer': 'https://app.adjust.com/1ac0i5al?campaign=Tools&adgroup=video-enhancer',
  anime: 'https://app.adjust.com/1ac0i5al?campaign=Tools&adgroup=ai-anime-generator',
};

const Smartbanner = () => {
  const { platform } = usePlatform();
  const router = useRouter();
  const [link, setLink] = useState(LinkMap.default);

  const handleRouteChange = (router: string) => {
    let assigned = false;
    Object.keys(LinkMap).forEach((key) => {
      if (router.includes(key)) {
        assigned = true;
        setLink(LinkMap[key]);
      }
      if (router === '/') {
        assigned = true;
        setLink(LinkMap.home);
      }
    });

    if (!assigned) {
      setLink(LinkMap.default);
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    handleRouteChange(router.asPath);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    document.addEventListener(
      customLinkUpdate,
      (
        event: Event & {
          detail?: { link?: string };
        }
      ) => {
        const {
          detail: { link },
        } = event;
        if (link) {
          setLink(link);
        }
      }
    );
  }, []);

  return (
    <div
      id="smartbnaner"
      className="flex h-[66px] w-full items-center justify-between bg-ab-orange bg-gradient-to-r from-ab-orange to-ab-orange-2 px-5 text-white lg:hidden"
    >
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '5px',
          }}
        >
          <p className="mr-2 text-xl">Airbrush</p>
          <div className="flex justify-center">
            {new Array(5).fill(0).map((_, index) => (
              <Start index={index} key={index} />
            ))}
          </div>
        </div>
        <p className="text-xs">All-in-one Photo/Video Editor</p>
      </div>
      <a href={link} target="__blank" rel="nofollow" title="smart banner app download">
        <Image
          width={125}
          height={38}
          src={platform === 'iOS' ? downloadApple : downloadGoogle}
          alt="smart banner"
        />
      </a>
    </div>
  );
};

export default Smartbanner;
