// 返回对应的域名
export function getDomain() {
  let domain = 'airbrush.com';
  if (typeof window === 'undefined') {
    return domain;
  }

  const isIO = location.hostname.includes('airbrush.io');

  domain = isIO ? 'airbrush.io' : 'airbrush.com';
  if (process.env.APP_ENV === 'development') {
    domain = isIO ? 'dev.airbrush.io' : 'dev.airbrush.com';
  } else if (process.env.APP_ENV === 'beta') {
    domain = isIO ? 'beta.airbrush.io' : 'beta.airbrush.com';
  }
  return domain;
}

// 服务端，返回对应的域名
export function getDomainCom() {
  let domain = 'airbrush.com';
  if (process.env.APP_ENV === 'development') {
    domain = 'dev.airbrush.com';
  } else if (process.env.APP_ENV === 'beta') {
    domain = 'beta.airbrush.com';
  }
  return domain;
}

// 获取Node服务接口地址
export function getAirbrushApi() {
  const domain = getDomain();
  let api = `https://${domain}/airbrush-api`;
  if (process.env.APP_ENV === 'development') {
    api = `http://${domain}:8080/airbrush-api`;
  }
  return api;
}
