import { getImgConfig } from './toolkit';
import { WEBP_FORMAT } from './constant';

import type { TypeCfImage } from './typing';

// 设置了sizes，生成宽度srcSet
// 设置了width，生成该宽度的1x和2x
// 同时设置了sizes和width，sizes优先
export default function CfImage(props: TypeCfImage) {
  const { src, width, height, sizes, quality, priority, alt, style = {}, className } = props;
  return (
    <picture>
      <source
        {...getImgConfig({
          src,
          sizes,
          quality,
          width,
          height,
          priority,
          format: WEBP_FORMAT,
        })}
        type={`image/${WEBP_FORMAT}`}
      />

      <img
        loading="lazy"
        key={src}
        alt={alt}
        style={{
          display: 'block',
          ...(width
            ? {
                width: `${width}px`,
              }
            : {}),
          ...style,
        }}
        className={className}
        {...getImgConfig({
          src,
          sizes,
          quality,
          priority,
          width,
          height,
        })}
      />
    </picture>
  );
}
