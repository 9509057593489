interface Props {
  pcBox: React.ReactNode;
  mobileBox: React.ReactNode;
  pcStyle?: React.CSSProperties | string;
  mobileStyle?: React.CSSProperties;
  pcClassName?: string;
  mobileClassName?: string;
  pcDisplay?: string;
  mobileDisplay?: string;
}

export default (props: Props) => {
  const {
    pcBox,
    mobileBox,
    pcStyle = '',
    mobileStyle = '',
    pcClassName = {},
    mobileClassName = {},
    pcDisplay = 'block',
    mobileDisplay = 'block',
  } = props;

  return (
    <>
      <div className={`hidden ${pcStyle} md:${pcDisplay}`} style={pcClassName}>
        {pcBox}
      </div>
      <div className={`${mobileDisplay} ${mobileStyle} md:hidden`} style={mobileClassName}>
        {mobileBox}
      </div>
    </>
  );
};
