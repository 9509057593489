const BASE_VIEWPOINT = 1440;

// M的设计稿基准宽度
const BASE_VIEWPOINT_MOBILE = 390;

export function vw(px: number) {
  const num = (px / BASE_VIEWPOINT) * 100;
  return `${num}vw`;
}

export function vwM(px: number) {
  const num = (px / BASE_VIEWPOINT_MOBILE) * 100;
  return `${num}vw`;
}

export function vwNum(px: number) {
  return (px / BASE_VIEWPOINT) * 100;
}

((typeof window === 'undefined' ? global : window) as any).vw = vw;
((typeof window === 'undefined' ? global : window) as any).vwM = vwM;
((typeof window === 'undefined' ? global : window) as any).vwNum = vwNum;
