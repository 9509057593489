// 提示停留时间
export const TIPS_DELAY_TIME = 2500;

// 7天 单位：秒
export const COOKIE_MAX_AGE = 604800;

// cookie
export const COOKIE_AGREE = 'cookie_agree';

// 多语言cookie
export const COOKIE_LOCALE = 'locale';

// utm_source渠道cookie
export const COOKIE_UTM_SOURCE = 'utm_source';

// 多语言列表
export const LOCALE = {
  DEFAULT: 'en',

  LIST: ['en', 'pt', 'es', 'ru', 'de', 'fr'],

  MAP: {
    en: 'English',
    pt: 'Português',
    es: 'Español',
    ru: 'Русский',
    de: 'Deutsch',
    fr: 'Français',
  },

  OPTIONS: [
    {
      label: 'English',
      value: 'en',
    },
    {
      label: 'Português', // 葡萄牙语
      value: 'pt',
    },
    {
      label: 'Español', // 西班牙语
      value: 'es',
    },
    {
      label: 'Русский', // 俄文
      value: 'ru',
    },
    {
      label: 'Deutsch', // 德语
      value: 'de',
    },
    {
      label: 'Français', // 法语
      value: 'fr',
    },
  ],
};

// 移动端适配尺寸
export const MOBILE_SIZE = 768;
