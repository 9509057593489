export const DSN =
  'https://270109f8f735ea21b7e47c503e9b8158@o4506023827800064.ingest.sentry.io/4506024021655552';

// 错误类型
export const TYPE = {
  // 静态资源加载失败
  ERROR_STATIC_FILE: 'load_error',

  // API失败
  ERROR_API: 'api_error',

  // 渲染失败
  ERROR_RENDER: 'render_error',

  // 职业照生成失败
  ERROR_HEADSHOTS: 'headshots_api_error',
};

// sentry trace id
export const TRACE_ID = 'x-trace-id';

// 静态资源采样率排除比率
export const IGNORE_RATE_STATIC_FILE = 9;

// 性能采样率
export const TRACES_SAMPLE_RATE = 0.1;

// 缓存在本地的sentry trance id
export const SENTRY_TRACE_ID = 'sentry_trace_id';
