// mac webview宽度940
const macSize = 940;

// 移动端尺寸小于mac
export const mobileSize = macSize - 10;

// pc的尺寸
export const pcSize = macSize + 10;

export const mediaQuery = (size: number, inner: string, isMax = true) => {
  return `@media (${isMax ? 'max' : 'min'}-width: ${size}px) {
    ${inner}
}`;
};

export const mobile = (inner) => {
  return mediaQuery(mobileSize, inner, true);
};

export const macApp = (inner) => {
  return `@media (min-width: ${mobileSize}px) and (max-width: ${pcSize}px) {
    ${inner}
}`;
};

export const pc = (inner) => {
  return mediaQuery(pcSize, inner, false);
};

((typeof window === 'undefined' ? global : window) as any).mediaQuery = mediaQuery;
((typeof window === 'undefined' ? global : window) as any).mobile = mobile;
((typeof window === 'undefined' ? global : window) as any).macApp = macApp;
((typeof window === 'undefined' ? global : window) as any).pc = pc;
