import { COOKIE_AGREE } from 'constants/common';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ls from 'utils/localStorage';
import { isAPPClient } from 'utils/client-msg';
import { Cookie, Footer, Reject, Accept } from './styleComponent';

export default () => {
  const [visible, setVisible] = useState(false);

  const router = useRouter();

  // 同意隐私协议
  const agreeHandle = () => {
    setVisible(false);
    ls.set(COOKIE_AGREE, true);
  };

  useEffect(() => {
    const hasAgree = ls.get(COOKIE_AGREE);
    if (
      !hasAgree &&
      !['/tools/studio', '/tools/desktop'].includes(router.pathname) &&
      !isAPPClient
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [router.pathname]);

  return (
    <Cookie visible={visible}>
      {$t({
        defaultMessage: `We use cookies to provide you with better experience. By continuing, you acknowledge that you have read, understood and agreed our Cookie Policy.`,
        id: 'JiiwAF',
      })}
      <Footer>
        <Reject onClick={() => setVisible(false)}>
          {$t({ defaultMessage: 'Reject', id: 'VzIOKf' })}
        </Reject>
        <Accept onClick={agreeHandle}>{$t({ defaultMessage: 'Accept', id: 'sjzLbX' })}</Accept>
      </Footer>
    </Cookie>
  );
};
