import { APP_UA, APP_UA_NEW, APP_UA_NEW_BRAND } from 'constants/app';
import queryString from 'query-string';

/**
 * 新品牌 AirBrush Mac (新)客户端
 * isNewAPPClient: 该标识只作一个旧版的新版本确认，若是旧版本则显示专属ui（若其为 true 则 isAPPClient 也为true)
 * 故 isNewBrandAPPClient 需要同时与 isAPPClient 和 isNewAPPClient 作兼容
 * isAPPClient 作用是判断当前 web环境 还是 客户端环境，不区分 之前的ABS品牌的客户端新旧版本
 */
export const isNewBrandAPPClient =
  typeof navigator !== 'undefined' && navigator?.userAgent?.includes(APP_UA_NEW_BRAND);

// AirBrush Studio(旧) 客户端
export const isAPPClient =
  (typeof navigator !== 'undefined' && navigator?.userAgent?.includes(APP_UA)) ||
  isNewBrandAPPClient;

// AirBrush Studio(旧) 新客户端
export const isNewAPPClient =
  typeof navigator !== 'undefined' && navigator?.userAgent?.includes(APP_UA_NEW);

// AirBrush Studio(旧) 客户端版本号
export const appVersion =
  typeof navigator !== 'undefined' &&
  (navigator?.userAgent?.split(APP_UA_NEW_BRAND)?.[1]?.match(/\d+\.(?:\d+\.)*\d+/)?.[0] ||
    navigator?.userAgent?.split(APP_UA)?.[1]?.match(/\d+\.(?:\d+\.)*\d+/)?.[0]);

// AirBrush Studio(旧) 客户端
export const getIsAPPServer = (ua = '') => {
  return ua?.includes(APP_UA) || ua?.includes(APP_UA_NEW_BRAND);
};

/**
 * mac客户端通信
 * @param type
 * @param params
 * @param callback
 */
export const macMessage = (
  type: string,
  params: Record<string, any> = {},
  callback = (...args) => {
    console.log(args);
  }
) => {
  try {
    const clientCallbackId = `${Math.floor(Math.random() * 1000)}_${+new Date()}`;
    window[clientCallbackId] = callback;

    // 传给客户端数据
    const data = {
      type,
      callback: clientCallbackId,
      data: params,
    };

    console.log('通知客户的数据: ', data);

    (window as any).webkit.messageHandlers.Native.postMessage(data);
  } catch (err) {
    console.log(err);
  }
};

// mac客户端通信 同步形式
export const macMessageSync = (type: string, params: Record<string, any> = {}) => {
  return new Promise((resolve) => {
    macMessage(type, params, (data) => {
      resolve(data);
    });
    // 5秒超时，防止APP出现意外情况
    setTimeout(() => {
      resolve(null);
    }, 5000);
  });
};

/**
 * 拉起客户端
 * @param params 客户端打开webview的页面的参数
 * @param path 客户端需要打开webview的页面，默认为登录页。
 */
export const schemaUrl = (params = {}, path = '/account/login') => {
  let schemaParam = '';

  if (!isAPPClient) {
    if (path) {
      schemaParam += `${location.origin}${path}`;
      const str = queryString.stringify(params);
      if (str) {
        schemaParam += `?${str}`;
      }
    }

    const schema = `airbrushstudio://|web|${schemaParam}`;

    console.log('schema: ', schema);

    setTimeout(() => {
      const a = document.createElement('a');
      a.href = schema;
      document.body.appendChild(a);
      a.click();
    }, 500);
  }
};

// AirBrush Studio(旧) 客户端版本控制，比较传入版本号与客户端当前版本号
// true:  客户端当前版本大于等于传入版本/不在app中
// false: 客户端当前版本小于传入版本
export const compareAPPVersion = (version: string) => {
  if (version.trim() === '' || !appVersion) {
    return true;
  }

  const versions = version.split('.');
  const majar = Number(versions?.[0] || '');
  const minor = Number(versions?.[1] || '');
  const patch = Number(versions?.[2] || '');

  const appVersions = appVersion.split('.');
  const appMajar = Number(appVersions?.[0] || '');
  const appMinor = Number(appVersions?.[1] || '');
  const appPatch = Number(appVersions?.[2] || '');

  if (appMajar > majar) {
    return true;
  }

  if (appMajar === majar) {
    if (appMinor > minor) {
      return true;
    }
    if (appMinor === minor && appPatch >= patch) {
      return true;
    }
    return false;
  }

  return false;
};
