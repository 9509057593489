import { JSBrige } from 'constants/app';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { apiSignOut, apiUserInfo } from 'services/login';
import { useCallback, useEffect, useState } from 'react';
import { getPageUrl, getRedirect } from 'utils/pageUrl';
import { isAPPClient, macMessage } from 'utils/client-msg';
import { useDispatch } from 'react-redux';
import { updateLoginStatus } from 'store/account';
import { getDomain } from 'utils/domain';

export type TypeToken = {
  access_token: string;
  refresh_token: string;
  expires_in?: number;
  expireTime?: number;
  id_token?: string;
};

export type TypeUserInfo = {
  email?: string;
  name?: string;
  uid?: string;
  openId?: string;
  displayName?: string;
  token?: string;
};

// 获取token
export function getToken() {
  if (typeof window === 'undefined') {
    return;
  }

  const { token } = Cookies.get();
  return token;
}

// 设置token
export function setToken(token) {
  Cookies.set('token', token);
}

// 删除token
export function removeToken() {
  const options = { domain: getDomain() };
  Cookies.remove('uid', options);
  Cookies.remove('email', options);
  Cookies.remove('name', options);
  Cookies.remove('token', options);
  Cookies.remove('login_type', options);
  Cookies.remove('open_id', options);
}

// 获取登录信息
export function getUserInfo(): TypeUserInfo {
  if (typeof window === 'undefined') {
    return;
  }

  const { email, name, uid, token, open_id: openId } = Cookies.get();
  const displayName = email || name || uid;
  return token ? { email, name, displayName, uid, token, openId } : undefined;
}

// 获取登录信息
export function useUserInfo() {
  const [userInfo, setUserInfo] = useState<TypeUserInfo>();
  useEffect(() => {
    setUserInfo(getUserInfo());
  }, []);

  return userInfo;
}

// 跳转登录
export function getLoginPage() {
  return getPageUrl('/account/login');
}

// 跳转登录, redirectFromUrl为true，重定向链接只能来自于url参数
export function toLoginPage(redirectFromUrl = false) {
  let redirect = getRedirect();
  if (!redirect && !redirectFromUrl) {
    redirect = location.href;
  }

  return `/account/login?redirect=${redirect}`;
}

// 跳转回重定向页面，否则跳回首页
export function toRedirectPage(loc = location) {
  const parsed = queryString.parse(loc.search);
  const redirect = parsed?.redirect as string;
  let url = redirect || '/';
  if (redirect === 'undefined') {
    url = '/';
  }
  loc.href = url;
}

// 从接口获取用户信息
let userInfoRemotePromise;
export async function getUserInfoRemote() {
  // 先判断是否登录
  const token = getToken();
  if (!token) {
    return;
  }
  if (!userInfoRemotePromise) {
    userInfoRemotePromise = apiUserInfo().then(({ data, code }) => {
      if (code === 30002) {
        // token失效
        removeToken();
        if (isAPPClient) {
          // 退出登录
          macMessage(JSBrige.TYPE.LOGOUT);
        }
        return;
      }
      return data;
    });
  }

  return userInfoRemotePromise;
}
export function resetUserInfo() {
  userInfoRemotePromise = null;
}

export function signOut() {
  apiSignOut();
  removeToken();
}
export function useSignOut() {
  const dispatch = useDispatch();
  const callback = useCallback(() => {
    dispatch(updateLoginStatus(false));
    signOut();
  }, [dispatch]);
  return callback;
}
