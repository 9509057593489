import Head from 'next/head';
import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { IntlProvider } from 'react-intl';
import { initIntl, mergeEn } from 'utils/intl';
import { useRouter } from 'next/router';
import 'utils/sentry'; // sentry 尽早初始化
import 'utils/mediaQuery';
import 'utils/px2vw';
import MainLayout from 'components/layout/MainLayout';
import Main from 'components/common/Main';
import { report } from 'utils/analytics';
// import { getUserInfoRemote } from 'utils/login';
// import { installChatbot } from 'utils/installPlugin';
// import AccountInit from 'components/common/AccountInit';
import 'styles/globals.css';
import 'styles/airbrush-blog.scss';
import 'styles/markdown.css';
import 'components/toolbox/styles/toolbox.css';

type CustomAppProps = AppProps & {
  Component: {
    CustomLayout?: React.FC;
  };
};

function MyApp({ Component, pageProps }: CustomAppProps) {
  const Layout = Component?.CustomLayout || MainLayout;

  // 初始化多语言
  const router = useRouter();
  const { locale } = router;
  const messages = mergeEn(locale);
  initIntl(locale, messages);

  useEffect(() => {
    if (location.protocol === 'http:' && process.env.APP_ENV !== 'development') {
      // 兼容有些手机没有重定向到https
      location.href = location.href.replace('http:', 'https:');
    }
  }, [router.pathname]);

  useEffect(() => {
    const sessionKey = 'product_enter_reported';
    const value = sessionStorage.getItem(sessionKey);
    if (!value) {
      report('product_enter');
      sessionStorage.setItem(sessionKey, Date.now().toString());
    } else if (Date.now() - Number(value) > 1000 * 60 * 60 * 0.5) {
      report('product_enter');
      sessionStorage.setItem(sessionKey, Date.now().toString());
    }
  }, [router.pathname]);

  useEffect(() => {
    const handleRouteChange = () => {
      report('page_view');
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    (window as any).intlMessages = messages;
  }, [messages]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no,viewport-fit=cover"
        />
        <meta name="yandex-verification" content="1d0a5111ad8ece1b" />
        <title>
          {$t({
            defaultMessage: `AirBrush - The Best AI-Powered Portrait Editor for Creators`,
            id: 'BU/jrd',
          })}
        </title>
        <meta
          name="og:title"
          property="og:title"
          content={$t({
            defaultMessage: `AirBrush - The Best AI-Powered Portrait Editor for Creators`,
            id: 'BU/jrd',
          })}
        />
        <meta
          name="description"
          content={$t({
            defaultMessage: `Bring powerful images to life in seconds, AirBrush has all the tools you need to create everything you can imagine`,
            id: 'A0+SGT',
          })}
        />
        {(process.env.APP_ENV === 'production' || process.env.APP_ENV === 'beta') && (
          <>
            <script
              type="text/javascript"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `(function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "ltt0ty7jjm");`,
              }}
            />
            <script
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `window.uetq = window.uetq || [];`,
              }}
            />
          </>
        )}
      </Head>
      <Main>
        <IntlProvider locale={locale} key={locale} messages={messages}>
          {/* <AccountInit /> */}
          <Layout {...pageProps}>
            <Component {...pageProps} />
          </Layout>
        </IntlProvider>
      </Main>
    </>
  );
}

export default MyApp;
