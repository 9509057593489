import { createIntl, createIntlCache } from 'react-intl';
import type { MessageDescriptor } from 'react-intl';

import en from 'lang/smartcat/en.json';
import es from 'lang/smartcat/es.json';
import pt from 'lang/smartcat/pt.json';
import ru from 'lang/smartcat/ru.json';
import de from 'lang/smartcat/de.json';
import fr from 'lang/smartcat/fr.json';

// 语言列表
const langObj = {
  en,
  es,
  pt,
  ru,
  de,
  fr,
};

const cache = createIntlCache();

// 与源语言英语合并
export function mergeEn(target: string) {
  return {
    ...en,
    ...langObj[target],
  };
}

// 设置全局$t
export function initIntl(locale = 'en', messages) {
  const intl = createIntl(
    {
      locale,
      messages,
      onError(err) {
        if (err?.code === 'MISSING_DATA') {
          return;
        }
        console.log(`初始化多语言错误: ${JSON.stringify(err)}`);
      },
    },
    cache
  );

  const $t = (descriptor: MessageDescriptor, values = {}) => {
    return intl.formatMessage(descriptor, values);
  };

  ((typeof window === 'undefined' ? global : window) as any).$t = $t;
  ((typeof window === 'undefined' ? global : window) as any).intl = intl;
}
