import { DSN, TYPE, TRACE_ID, SENTRY_TRACE_ID, TRACES_SAMPLE_RATE } from 'constants/sentry';
import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';
import ls from 'utils/localStorage';
import { getUserInfo } from 'utils/login';

type TypeReportError = { type: string; message?: any };

interface Grafanacrumb extends Sentry.Breadcrumb {}

// sentry处理开关
const enableSentry = process.env.APP_ENV === 'production' && typeof window !== 'undefined';

const ignorePages = [/\blegal\b/];

export interface ErrorMessage {
  level?: 'fatal' | 'error' | 'warning' | 'info';
  type?: string;
  url: string;
  status?: string;
  data?: object;
  params?: string | object;
  message: string;
}

export interface StardardErrorMessage extends ErrorMessage {
  level: 'fatal' | 'error' | 'warning' | 'info';
  type: string;
}

/**
 * 采样比率
 * @param rate 范围1-10
 */
export function ignoreRate(rate: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10): boolean {
  // 值范围 [1-10]
  const num = Math.ceil(Math.random() * 10);
  return num <= rate;
}

// 获取sentry唯一id，一个用户分配一个唯一id
let traceId: string;
export function getTraceId() {
  if (traceId) {
    return traceId;
  }

  let id = ls.get(SENTRY_TRACE_ID);
  if (!id) {
    id = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    ls.set(SENTRY_TRACE_ID, id);
  }
  traceId = id;
  return id;
}

const sentryCaptureMessage = ({ type, message }: TypeReportError, level: SeverityLevel) => {
  if (!enableSentry) {
    return;
  }
  const data: TypeReportError = { type };
  if (message) {
    data.message = message;
  }
  Sentry.captureMessage(JSON.stringify(data), level);
};

// fatal致命错误，5分钟出现1次就告警
export const fatalReport = ({ type, message }: TypeReportError) => {
  sentryCaptureMessage({ type, message }, 'fatal');
};

// error致命错误，10分钟影响20%用户
export const errorReport = ({ type, message }: TypeReportError) => {
  sentryCaptureMessage({ type, message }, 'error');
};

// warning致命错误，10分钟影响20%用户
export const warningReport = ({ type, message }: TypeReportError) => {
  sentryCaptureMessage({ type, message }, 'warning');
};

// https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/breadcrumbs/
export const newGrafanaBreadcrumb = (url: string, params: Grafanacrumb) => {
  if (!enableSentry) {
    return;
  }
  if (/\/airbrush-api/gi.test(url)) {
    const traceId = getTraceId();
    Sentry.addBreadcrumb({
      ...params,
      // grafana平台链接 自动筛选，携带traceId的所有请求
      message: `grafana错误日志: https://grafana.pixocial.io/explore?orgId=1&left=%7B%22datasource%22:%22WRFf0jiVz%22,%22queries%22:%5B%7B%22refId%22:%22A%22,%22editorMode%22:%22builder%22,%22expr%22:%22%7Bapp%3D%5C%22airbrush-api%5C%22%7D%20%7C%3D%20%60${traceId}%60%22,%22queryType%22:%22range%22,%22legendFormat%22:%22%22,%22maxLines%22:null%7D%5D,%22range%22:%7B%22from%22:%22now-3h%22,%22to%22:%22now%22%7D%7D`,
    });
  }
};

// 静态资源加载异常 https://docs.sentry.io/platforms/javascript/troubleshooting/#capturing-resource-404s
export function staticResourceMonitor() {
  if (typeof window === 'undefined') {
    return;
  }
  // 监控资源异常
  document.body.addEventListener(
    'error',
    (event) => {
      if (!event.target) {
        return;
      }
      const target = event.target as any;
      warningReport({
        type: TYPE.ERROR_STATIC_FILE,
        message: target?.src || target?.currentSrc || target?.href,
      });
    },
    true
  );
}

// sentry初始化
function sentryInit() {
  if (!enableSentry) {
    return;
  }

  if (typeof window === 'undefined') {
    return;
  }

  const isIgnorPage = ignorePages.every((page) => page.test(window.location.pathname));

  if (isIgnorPage) {
    return;
  }

  Sentry.init({
    dsn: DSN,

    // 监听性能
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [/airbrush.com/],
      }),
    ],

    tracesSampleRate: TRACES_SAMPLE_RATE,

    environment: process.env.APP_ENV,

    beforeSend(event, hint) {
      const originalException = hint?.originalException as Error;

      // 过滤资源加载错误。
      // if (
      // event?.message?.includes(TYPE.ERROR_STATIC_FILE);
      // (ignoreRate(IGNORE_RATE_STATIC_FILE) || // 采样比率
      //   location?.search?.includes('third-login')) // 第三方登录成功跳转页忽略资源加载错误。这个页面调用登录接口后会关闭页面，导致很多资源加载会异常
      // ) {
      //   return null;
      // }

      // 过滤资源加载失败
      if (originalException?.message?.includes(TYPE.ERROR_STATIC_FILE)) {
        return null;
      }

      // 过滤第三方包firebase报错
      if (originalException?.stack?.includes('FirebaseError')) {
        return null;
      }

      // 过滤浏览器调整宽度原生报错
      if (originalException?.message?.includes('ResizeObserver loop')) {
        return null;
      }

      // 过滤react内部报错。ssr和水合 html不一致
      if (originalException?.stack?.includes('React error')) {
        return null;
      }

      console.log(originalException?.message);

      return event;
    },

    // 只监听项目文件错误，若为第三方文件错误，则忽略
    allowUrls: [/airbrush.com/],

    denyUrls: [/^https:\/\/airbrush\.com\/legal\//, /^https:\/\/airbrush\.com\/privacy\//],
  });

  // 参考：https://blog.sentry.io/trace-errors-through-stack-using-unique-identifiers-in-sentry/
  Sentry.configureScope((scope) => {
    const userInfo = getUserInfo();

    scope.setTag(TRACE_ID, getTraceId());
    if (userInfo) {
      scope.setTag('uid', userInfo.uid);
      scope.setTag('email', userInfo.email);
    }
  });

  // staticResourceMonitor();
}

sentryInit();

export const { ErrorBoundary } = Sentry;
