import { createSlice } from '@reduxjs/toolkit';

export enum tabs {
  smart = 'Smart',
  pro = 'Pro',
}

export const aigcSlice = createSlice({
  name: 'aigc',
  initialState: {
    list: [],
    loading: false,
    activeTab: tabs.smart,
    disabledGenBtn: false,
    downloadList: [],
    cleverFlag: false,
    controlShell: undefined,
    enlargeList: [],
    initIndex: 0,
  },
  reducers: {
    updateList: (state, { payload }) => {
      state.list = [payload, ...state.list];
    },
    clearList: (state) => {
      state.list = [];
    },
    updateActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    resetActiveTab: (state) => {
      state.activeTab = tabs.smart;
    },
    updateLoading: (state, { payload }) => {
      state.loading = payload;
    },
    resetLoading: (state) => {
      state.loading = false;
    },
    setDisabledGenBtn: (state, { payload }) => {
      state.disabledGenBtn = payload;
    },
    resetDisabledGenBtn: (state) => {
      state.disabledGenBtn = false;
    },
    updateDownloadList: (state, { payload }) => {
      if (state.downloadList.includes(payload)) {
        state.downloadList = state.downloadList.filter((item) => item !== payload);
        return;
      }
      state.downloadList.push(payload);
    },
    clearDownloadList: (state) => {
      state.downloadList = [];
    },

    setControlShell: (state, { payload }) => {
      state.controlShell = payload;
    },
    resetControlShell: (state) => {
      state.controlShell = undefined;
    },
    updateEnlargeList: (state, { payload }) => {
      const { list = [], initIndex = 0 } = payload;
      state.enlargeList = list;
      state.initIndex = initIndex;
    },
  },
});

export const {
  updateList,
  clearList,
  updateActiveTab,
  updateLoading,
  setDisabledGenBtn,
  updateDownloadList,
  clearDownloadList,
  setControlShell,
  updateEnlargeList,
  resetActiveTab,
  resetControlShell,
  resetDisabledGenBtn,
  resetLoading,
} = aigcSlice.actions;

export default aigcSlice.reducer;
