// import AnimateLink from 'components/common/AnimateLink';
import CfImage from 'components/common/CfImage';
import Link from 'next/link';
import { CSSProperties, useCallback, useMemo, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import styles from './styles.module.scss';

interface Props {
  title: string;
  lists: {
    text: string;
    href: string;
    url?: string;
    target?: string;
    noFollow?: boolean;
    customStyle?: CSSProperties;
  }[];
}

export default (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { title, lists } = props;
  const ref = useRef<HTMLDivElement>(null);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const openHeight = useMemo(() => {
    if (!isOpen) {
      return 0;
    }
    const rem = getComputedStyle(document.documentElement).fontSize;
    return ref.current.scrollHeight / parseFloat(rem) + 2.875;
  }, [isOpen]);

  return (
    <li
      className="w-full overflow-hidden border-b border-solid border-ab-gray transition-all duration-200"
      style={isOpen ? { height: `${openHeight}rem` } : { height: '2.875rem' }}
    >
      <h2 className="flex h-[2.875rem] items-center justify-between" onClick={toggle}>
        {title}
        <CfImage
          src="https://gcs.beautyplus.com/7b03a60779a9dec681a15d5925d9d402.png"
          width={13}
          style={{
            height: '0.5rem',
            transition: 'transform 0.2s ease-in-out',
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </h2>
      <div className="flex flex-col" ref={ref}>
        {lists.map((item, index) => {
          return (
            <Link
              key={index}
              href={item.href}
              target={item.target}
              rel={item.noFollow ? 'nofollow' : undefined}
              className={`mb-3 text-sm text-[#7B7B7B] hover:text-ab-orange ${styles.hover}`}
            >
              {item.url && <ReactSVG src={item.url} className={`${styles.svgWidth}`} />}
              {item.text}
            </Link>
          );
        })}
      </div>
    </li>
  );
};
