import { Provider } from 'react-redux';
import { store } from 'store';
import { ErrorBoundary } from 'utils/sentry';

export default function Main({ children }) {
  return (
    <ErrorBoundary>
      <Provider store={store}>{children}</Provider>
    </ErrorBoundary>
  );
}
