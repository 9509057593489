import { COOKIE_UTM_SOURCE } from 'constants/common';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { getDomain } from 'utils/domain';

export default function utm() {
  if (typeof window === 'undefined') {
    return '';
  }
  const parsed = queryString.parse(location.search);
  const { utm_source: utmSource, install_source: installSource } = parsed;
  const channel = installSource || utmSource;
  if (channel) {
    // 只存在当前会话
    Cookies.set(COOKIE_UTM_SOURCE, channel as string, { path: '/', domain: getDomain() });
  }
  return Cookies.get(COOKIE_UTM_SOURCE) || '';
}
