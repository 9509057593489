import { COOKIE_LOCALE, LOCALE } from 'constants/common';
import Cookies from 'js-cookie';

let lang;
export default function getCurrentLang() {
  if (typeof window === 'undefined') {
    return LOCALE.DEFAULT;
  }

  if (!lang) {
    lang = Cookies.get(COOKIE_LOCALE) || LOCALE.DEFAULT;
  }
  return lang;
}
