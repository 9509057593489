const ls =
  typeof localStorage === 'object'
    ? {
        set(key: string, v: any) {
          let value = v;
          try {
            value = JSON.stringify(value);
          } catch (err) {
            console.warn(err);
          }

          try {
            localStorage.setItem(key, value);
          } catch (err) {
            console.warn(err);
          }
        },
        get(key: string) {
          let value = '';
          try {
            value = localStorage.getItem(key);
          } catch (err) {
            console.warn(err);
          }

          try {
            value = JSON.parse(value);
          } catch (err) {
            console.warn(err);
          }

          return value;
        },
        remove(key: string) {
          try {
            localStorage.removeItem(key);
          } catch (err) {
            console.warn(err);
          }
        },
      }
    : {
        set() {},
        get() {},
        remove() {},
      };

export const ss =
  typeof sessionStorage === 'object'
    ? {
        set(key: string, v: any) {
          let value = v;
          try {
            value = JSON.stringify(value);
          } catch (err) {
            console.warn(err);
          }

          try {
            sessionStorage.setItem(key, value);
          } catch (err) {
            console.warn(err);
          }
        },
        get(key: string) {
          let value = '';
          try {
            value = sessionStorage.getItem(key);
          } catch (err) {
            console.warn(err);
          }

          try {
            value = JSON.parse(value);
          } catch (err) {
            console.warn(err);
          }

          return value;
        },
        remove(key: string) {
          try {
            sessionStorage.removeItem(key);
          } catch (err) {
            console.warn(err);
          }
        },
      }
    : {
        set() {},
        get() {},
        remove() {},
      };

export default ls;
