// 客户端通信的类型
export const APP_UA = 'absapp';

// 新版客户端UA
export const APP_UA_NEW = 'absapp-';

// 新品牌客户端UA
export const APP_UA_NEW_BRAND = 'abmac-';

// 客户端通信的类型
export const JSBrige = {
  TYPE: {
    LOGIN: 'login', // 登录
    CLOSE: 'close', // 关闭webview
    OPEN: 'open', // 用默认浏览器打开url
    GET_TOKEN: 'getToken', // 从APP获取token
    BUY: 'buy', // 从APP获取token
    REPORT: 'report', // 埋点上报

    FIREBASE: 'firebase', // 登录获取firebase

    LOGOUT: 'logout', // 退出登录

    NEW_USER_COUNT_DOWN: 'newUserSale', // 新用户折扣获取倒计时

    DOWNLOAD: 'download', // 让客户端下载

    PRINT: 'print', // 客户端打印
  },
};

// 客户端埋点
export const FIREBASE = {
  MONTH: '1month',
  YEAR: 'annual',
};
