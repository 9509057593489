import { createSlice } from '@reduxjs/toolkit';
import { TeamInfo } from 'services/team.data';

type AccountState = {
  isLogin: boolean;
  isActive: boolean;
  customer: any;
  card: any;
  team: TeamInfo | undefined;
  personal: TeamInfo | undefined;
  hadTeam: boolean;
  hadAdmin: boolean;
};

// 用户数据
export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    // 判断状态是否激活。
    isActive: false,

    // 登录状态
    isLogin: undefined,
    // chargebee个人数据
    customer: undefined,
    // chargebee 卡信息
    card: undefined,
    // 自由人信息
    personal: undefined,
    // 团队信息
    team: undefined,
    // 是否是团队
    hadTeam: false,
    // 是否是团队管理员
    hadAdmin: false,
  } as AccountState,
  reducers: {
    // 更新登录状态
    updateLoginStatus: (state, { payload }) => {
      state.isLogin = payload;
    },

    // 更新账号信息
    updateAccount: (state, { payload }) => {
      // 状态更新了
      state.isActive = payload?.isActive || false;

      state.customer = payload?.customer;

      state.card = payload?.card;

      state.team = payload?.team;

      state.personal = payload?.personal;

      state.hadTeam = payload?.hadTeam;

      state.hadAdmin = payload?.hadAdmin;
    },
  },
});

export const { updateAccount, updateLoginStatus } = accountSlice.actions;

export default accountSlice.reducer;
