import downloadListURls from 'constants/download';
import { report } from 'utils/analytics';
import { getAirbrushApi } from 'utils/domain';
import { getSystem } from 'utils/getSystem';
import getUtm from './utm';

export function getDownloadMac() {
  const utm = getUtm();
  let url;
  if (utm) {
    // aw获取最新渠道包
    url = `${getAirbrushApi()}/download/${utm}`;
  } else {
    // 默认web包
    url = downloadListURls.mac;
  }
  return url;
}

// 下载Mac
export function startDownloadMac() {
  if (getSystem() === 'windows_web') {
    return $t({ defaultMessage: 'AirBrush is not available on Windows PC', id: 'HJmbF6' });
  }
  const url = getDownloadMac();

  const ele = document.createElement('a');
  ele.href = url;
  ele.download = '';
  ele.click();
}

export function getPlatformLink() {
  let appLink: string;
  let platform: 'iOS' | 'Android' | 'Mac';

  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    // AB-iOS
    appLink = downloadListURls.ios;
    platform = 'iOS';
  } else if (/Android/i.test(navigator.userAgent)) {
    // AB-Android
    appLink = downloadListURls.android;
    platform = 'Android';
  } else {
    // ABS
    appLink = getDownloadMac();
    platform = 'Mac';
  }

  return {
    appLink,
    platform,
  };
}

// 桌面端直接下载ABS客户端；移动端区分安卓和iOS, 安卓跳转到google play，iOS跳转到appstore
export function downloadClick() {
  const { appLink, platform } = getPlatformLink();

  const DownloadType = {
    iOS: 'IOS',
    Android: 'ANDROID',
    Mac: 'MAC',
  };

  report('abs_download_free_trial', {
    download_app: DownloadType[platform],
  });

  if (platform === 'Mac') {
    const ele = document.createElement('a');
    ele.href = appLink;
    ele.download = '';
    ele.click();
  } else {
    location.href = appLink;
  }
}
