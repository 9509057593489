import styled, { css } from 'styled-components';

export const Cookie = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 11;
  width: 323px;
  background-color: #222222;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  padding: 20px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(246, 246, 246, 0.7);
  border-radius: 10px;
  ${mobile(css`
    bottom: 0;
    right: 0;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
  `)}
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  ${mobile(`
    
  `)}
`;

export const Bottom = styled.div`
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  width: 131px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  ${mobile(`
    width: ${vwM(165)}
  `)}
`;
export const Reject = styled(Bottom)`
  border: 1px solid rgba(246, 246, 246, 0.4);
  backdrop-filter: blur(24px);
`;
export const Accept = styled(Bottom)`
  background: linear-gradient(104.37deg, #ff9b52 9.97%, #ff6e37 89.57%);
  backdrop-filter: blur(24px);
  color: #fff;
`;
