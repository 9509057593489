import Header from 'components/index/Header';
import Navigation from 'components/index/Navigation';
import Footer from 'components/common/Footer';
import Cookies from 'components/common/Cookies';
// import MigratePipe from 'components/aigc/common/MigratePipe';

const MainLayout = ({
  children,
  showLoginModal,
}: {
  children: React.ReactNode;
  showLoginModal?: boolean;
}) => {
  return (
    <>
      <Header showLoginModal={showLoginModal} />
      {children}
      <Navigation />
      <Footer></Footer>
      <Cookies></Cookies>
    </>
  );
};

export default MainLayout;
