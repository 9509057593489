import { report } from 'utils/analytics';

export enum DownloadType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  MAC = 'MAC',
}

const downloadReport = (param: Object = {}) => {
  report('abs_download_free_trial', {
    ...param,
  });
};

export const downloadReportExposure = (param: Object = {}) => {
  report('download_exposure', {
    ...param,
  });
};

export const downloadReportClick = (param: Object = {}) => {
  report('download_click', {
    ...param,
  });
};

export default downloadReport;
