import { TRACE_ID } from 'constants/sentry';
import { getUserInfo, getUserInfoRemote } from 'utils/login';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties, setUserId } from 'firebase/analytics';
import utm from 'utils/utm';
import getCurrentLang from 'utils/getCurrentLang';
import { getSystem } from 'utils/getSystem';
import queryString from 'query-string';
import { getPathname } from 'utils/pageUrl';
import { getTraceId } from 'utils/sentry';
// import { abTestEvent, InitAbTest } from 'utils/abtest';
// import { initADS } from './ads';

// 参考文档；https://firebase.google.com/docs/analytics?authuser=0

let analytics;

function initGA() {
  if (!analytics) {
    const firebaseConfigBeta = {
      apiKey: 'AIzaSyBSxossA0LGqEVIlzrjdyEbEMBZWd9R2Hw',
      authDomain: 'airbrush-studio-test.firebaseapp.com',
      projectId: 'airbrush-studio-test',
      storageBucket: 'airbrush-studio-test.appspot.com',
      messagingSenderId: '260900785229',
      appId: '1:260900785229:web:89c0c01f178f325f18e004',
      measurementId: 'G-YSD261SB3C',
    };

    const firebaseConfigProd = {
      apiKey: 'AIzaSyB8XaGLKyMR1t8jT_NSMhsVi0acvtGL0Vk',
      authDomain: 'airbrush-studio.firebaseapp.com',
      projectId: 'airbrush-studio',
      storageBucket: 'airbrush-studio.appspot.com',
      messagingSenderId: '1042989971479',
      appId: '1:1042989971479:web:97af97ecdf38dda8018452',
      measurementId: 'G-TLXBKL58CH',
    };

    const firebaseConfig =
      process.env.APP_ENV === 'production' ? firebaseConfigProd : firebaseConfigBeta;

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
    // InitAbTest(app, analytics);
    // initADS();
  }
}

function reportEvent(eventName, params = {}) {
  initGA();

  // 获取用户id
  const userInfo = getUserInfo();

  if (userInfo?.uid) {
    // 设置用户属性
    setUserProperties(
      analytics,
      {
        athena_sub_id: userInfo?.uid,
        athena_open_id: userInfo?.openId,
      },
      {
        global: true,
      }
    );

    setUserId(analytics, userInfo?.uid);
  }

  logEvent(analytics, eventName, params);
}

let parsed;
export async function report(eventName, params = {}) {
  const userInfoRemote = await getUserInfoRemote();

  // 获取登录类型
  const loginType = userInfoRemote?.loginType || 'email';

  if (typeof window === 'undefined') {
    return;
  }

  if (!parsed) {
    parsed = queryString.parse(location.search);
  }

  // 获取用户属性
  const userInfo = getUserInfo();

  const lang = getCurrentLang();
  const pathname = getPathname();

  // const { meepo_abcode: abcode, meepo_abcount: abcount } =
  //   abTestEvent.ABTest?.encodeReportData() || {};

  reportEvent(eventName, {
    install_source: utm(),
    utm_source: utm(),
    athena_sub_id: userInfo?.uid,
    athena_open_id: userInfo?.openId,
    from: parsed?.from,
    login_type: loginType,
    lang,
    system: getSystem(),
    path_name: pathname,
    page_path: pathname + location.search,
    [TRACE_ID]: getTraceId(),
    // meepo_abcode: abcode,
    // meepo_abcount: abcount,
    version: '1.7.4',
    ...params,
  });
}
