import { configureStore } from '@reduxjs/toolkit';
import localeReducer from './locale';
import conversionModalReducer from './conversionModal';
import aigcReducer from './aigc';
import accountReducer from './account';

const reducer = {
  // 当前语言
  locale: localeReducer,
  aigc: aigcReducer,
  // 支付弹框
  conversionModal: conversionModalReducer,

  // 用户数据
  account: accountReducer,
};

export const store = configureStore({
  reducer,
  devTools: process.env.APP_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
