import { createSlice } from '@reduxjs/toolkit';

// 支付弹框
export const conversionModalSlice = createSlice({
  name: 'conversionModal',
  initialState: {
    // 登录弹框
    loginVisible: false,

    // 支付状态
    paymentVisible: false,
    paymentSuccess: false,
  },
  reducers: {
    // 登录弹框显示状态
    updateLoginVisible: (state, { payload }) => {
      state.loginVisible = payload;
    },

    // 支付弹框显示状态
    updatePaymentVisible: (state, { payload }) => {
      state.paymentVisible = payload;
    },
    // 支付成功
    updatePaymentSuccess: (state) => {
      state.paymentVisible = false;
      state.paymentSuccess = true;
    },
  },
});

export const { updateLoginVisible, updatePaymentVisible, updatePaymentSuccess } =
  conversionModalSlice.actions;

export default conversionModalSlice.reducer;
