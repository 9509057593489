import { COOKIE_LOCALE } from 'constants/common';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import getCurrentLang from 'utils/getCurrentLang';

export function getPageUrl(pathname) {
  const lang = Cookies.get(COOKIE_LOCALE) || 'en';

  const url = `${location.origin}/${lang}${pathname}`;

  return url;
}

// 获取url重定向参数
export function getRedirect(loc = location): string {
  const parsed = queryString.parse(loc.search);
  return parsed?.redirect as string;
}

// 获取没有多语言前缀的pathname
export function getPathname(loc = location): string {
  const lang = getCurrentLang();
  const pathname = loc.pathname.replace(`/${lang}`, '') || '/';

  return pathname;
}

// 非同源会报错，做兼容
export function historyReplace(url, needReload = false) {
  try {
    history.replaceState(null, '', url);

    if (needReload) {
      // history.replaceState不会刷新页面，需要手动触发
      location.reload();
    }
  } catch (err) {
    location.href = url;
  }
}
