const downloadListURls = {
  ios: 'https://apps.apple.com/us/app/airbrush-best-photo-editor/id998411110',
  android: 'https://play.google.com/store/apps/details?id=com.magicv.airbrush&hl=en',
  mac: 'https://airbrush.com/airbrush-api/download/web',

  homeLink: 'https://mypb.adj.st/p_homepage?adj_t=1avtvagl',
  toolsLink: 'https://mypb.adj.st/p_tools?adj_t=1a9uwpkt',
};

export default downloadListURls;
